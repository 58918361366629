import { useAtomValue } from 'jotai';
import React, { FC, memo } from 'react';

import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { openOldOrNewShareModal } from '../../../state/profiles-table-modal.atom';
import { IconUser } from '../../../ui/gologin-header/icons';
import { ITableCell } from '../interfaces';
import { Row, SharedValueContainer } from '../table/styles';

const SharingCell: FC<ITableCell> = (props) => {
  const { profileAtom } = props;

  const profile = useAtomValue(profileAtom);
  const { sharedEmails } = profile;
  let { sharesCount } = profile;
  sharesCount = sharedEmails?.length || sharesCount;

  if (!sharesCount) {
    return null;
  }

  if (!NEW_FEATURES.header) {
    return (
      <>
        <Row>
          {sharesCount}
        </Row>
      </>
    );
  }

  return (
    <SharedValueContainer
      onClick={(): void => openOldOrNewShareModal([profile.id])}
      colorIconType='activeGreen'
      iconColor='var(--00A987-profile-table)'
      iconHoveredColor='var(--00997A-profile-table)'
      iconType='stroke'
      textColor='var(--2B2B31-profile-table)'
      textHoveredColor='var(--00997A-profile-table)'
    >
      <IconUser padding={0} margin='0 8px 0 0' />
      {sharesCount}
    </SharedValueContainer>
  );
};

export default memo(SharingCell);
