/* eslint-disable max-lines */
import styled from '@emotion/styled';
import * as Sentry from '@sentry/react';
import { Icon, message, Popconfirm } from 'antd';
import { useAtomValue } from 'jotai';
import moment from 'moment';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import EditTagsMenu from './edit-tags-menu';
import { ILaunchProfileOrbita } from './interfaces/launch-profile-orbita.interface';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { E_ANALYTICS_ACTIONS } from '../../../../common/constants/analytics';
import { DEFAULT_MANY_PROFILE_NAME } from '../../../hooks';
import { IProfile } from '../../../interfaces';
import { importFilesContext, templatesContext, userContext, workspaceContext } from '../../../state';
import { useEditModeProfileId } from '../../../state/edit-mode-profile-id.atom';
import { getProfileRunStatus, runningProfilesCountAtom } from '../../../state/profile-run-statuses.atom';
import { filterProfilesByPermission, getProfilesList } from '../../../state/profiles-list.atom';
import {
  closeProfilesSettings,
  getProfilesSettingsState,
  openProfilesSettingsOfSelectedIds,
  useIsProfilesSettingsOpen,
} from '../../../state/profiles-settings-atom';
import { openOldOrNewShareModal } from '../../../state/profiles-table-modal.atom';
import {
  getProfilesTableSelectedIds,
  getProfilesTableSelectedProfilesActions,
  getSelectedProfilesByAction,
  resetProfilesTableSelectedIds,
  useProfilesTableSelectedIds,
} from '../../../state/profiles-table-selected-ids.atom';
import { checkCompetitorsForMigration } from '../../../ui/Footer/utils';
import IconAddFolder from '../../../ui/icons/IconAddFolder';
import IconAddMembersGreen from '../../../ui/icons/IconAddMembersGreen';
import IconDeleteProfile from '../../../ui/icons/IconDeleteProfile';
import IconFinger from '../../../ui/icons/IconFinger';
import TooltipCustom from '../../../ui/tooltip-custom';
import { openWebsitePricing } from '../../../utils/open-site';
import { generateBrowserIconsMassOpsPromise } from '../../browser-system-icons';
import { sendActionAnalytics } from '../../common/api';
import {
  MassActionsBlockEnd,
  MassActionsBlockLine,
  MassActionsButtonLine,
  MassActionsButtonLineDanger,
  MassActionsButtonText,
  MassActionsMarginBlock,
  MassActionsRow,
} from '../../common/custom-table/components/mass-actions-menu/styles';
import { getBrowserVersionCurrent } from '../../common/orbita-browser';
import { ExtensionsModal } from '../../modalsComponents/components/extensions-modal/extensions-modal';
import ImportDolphinProfilesModal from '../../modalsComponents/components/import-dolphin-profiles';
import MultipleProxyManager from '../../modalsComponents/components/multiple-proxy-manager';
import ProfileBookmarksModal from '../../modalsComponents/components/profile-bookmarks';
import { exportWorkspaceProfiles, profilesExport } from '../../modalsComponents/components/profiles-import-manager/api';
import { openTransferModal } from '../../modalsComponents/components/transfer-profile/open-transfer-modal';
import { IAddTag } from '../../tags/interfaces/tag.interface';
import { updateMultipleFingerprints } from '../../updateProfile/api';
import { stopProfileOrbita, stopProfileWeb } from '../actions';
import { cloneProfileMulti, cloneWorkspaceProfiles, deleteProfiles, deleteWorkspaceProfiles, removeMultipleInvites } from '../api';
import MultipleOperationsMenu from '../multiple-operations-menu';

let ipcRenderer: Electron.IpcRenderer;
const isElectron = !!window.require;
if (isElectron) {
  ({ ipcRenderer } = window.require('electron'));
}

const LoaderDiv = styled('div')`
  display: inline-block;
  margin-left: 10px;
`;

declare interface IProfilesMenu {
  openFoldersModal: (profileIds: string[]) => void;
  launchProfileOrbita: (opts: ILaunchProfileOrbita) => Promise<any>;
  updateProfilesList: () => void;
  updateTag: (tag: ITagBase) => Promise<void>;
  addTag: (params: IAddTag) => Promise<void>;
  removeTag: (tagId: string, isInSuggest: boolean) => Promise<void>;
  removeProfileTag: (profileIds: string[], tagId: string) => Promise<void>;
  changeProfilesData: (profileIds: string[], newData: Partial<IProfile>) => void;
  updateProfileFingerprints: (profilesData: IProfile[]) => void;
  isBrowserUpdating: boolean;
  versionLoadingNow: string | undefined;
}

const ProfilesMenu: FC<IProfilesMenu> = (props) => {
  const isProfilesSettingsOpen = useIsProfilesSettingsOpen();
  const selectedProfilesIds = useProfilesTableSelectedIds();
  const selectedProfilesActions = getProfilesTableSelectedProfilesActions();
  const runningProfilesCount = useAtomValue(runningProfilesCountAtom);

  const [isProcessing, changeProcessing] = useState<boolean>(false);
  const [showExportPayPopUp, setShowExportPayPopUp] = useState<boolean>(false);
  const [isShowProxyManager, setIsShowProxyManager] = useState<boolean>(false);
  const [extensionsModalVisible, setExtensionsModalVisible] = useState<boolean>(false);
  const [isShowBookmarksManager, setIsShowBookmarksManager] = useState<boolean>(false);
  const [isShowImportDolphinModal, setIsShowImportDolphinModal] = useState<boolean>(false);
  const [multipleLaunched, setMultipleLaunched] = useState<boolean>(false);
  const [anchorElTooltip, setAnchorElTooltip] = useState<HTMLElement|null>(null);
  const [anchorEditTagsEl, setAnchorEditTagsEl] = useState<null | HTMLElement>(null);

  const { t: translation } = useTranslation();

  const {
    launchProfileOrbita,
    updateTag,
    addTag,
    removeProfileTag,
    removeTag,
  } = props;

  const {
    migrationData = {},
    hasTrial,
    hasSuccessPayment,
    isQuickSettingsEnabled,
  } = useContext(userContext);

  const editModeProfileId = useEditModeProfileId();

  const { selectedTemplate } = useContext(templatesContext);
  const {
    id: workspaceId,
    isUnpaid: workspaceIsUnpaid,
    paymentIsTrial: workspaceIsTrial,
  } = useContext(workspaceContext);

  const { setManyProfilesName, setNeedOpenSpintaxPopup } = useContext(importFilesContext);

  useEffect(() => {
    window.addEventListener('keydown', EscapePressListener);
  }, []);

  useEffect(() => () => window.removeEventListener('keydown', EscapePressListener), []);

  useEffect((): (() => void) | void => {
    if (!isElectron) {
      return;
    }

    checkCompetitorsForMigration(migrationData, ipcRenderer).catch(() => void 0);
    ipcRenderer.on('dolphin-anty-detected', () => {
      setIsShowImportDolphinModal(true);
    });

    return () => ipcRenderer.removeAllListeners('dolphin-anty-detected');
  }, []);

  const EscapePressListener = (event: KeyboardEvent): void => {
    if (event.key !== 'Escape') {
      return;
    }

    setShowExportPayPopUp(prev => {
      if (!prev) {
        resetProfilesTableSelectedIds();
        closeProfilesSettings();
      }

      return false;
    });
  };

  const deleteOrLeaveMultipleProfiles = async () => {
    changeProcessing(true);
    const promises = [];
    const profilesForDelete = getSelectedProfilesByAction('delete');
    const profileIdsToDelete = profilesForDelete.map((profile) => profile.id);
    if (profileIdsToDelete.length) {
      if (NEW_FEATURES.workspaces) {
        promises.push(deleteWorkspaceProfiles(workspaceId, profileIdsToDelete));
      } else {
        promises.push(deleteProfiles(profileIdsToDelete));
      }

      if (ipcRenderer) {
        ipcRenderer.invoke('stop-multiple-profiles', profileIdsToDelete);
        ipcRenderer.invoke('del-profiles-ext-folder', profileIdsToDelete);
      }

      sendActionAnalytics(E_ANALYTICS_ACTIONS.clickedConfirmDeleteProfileViaMassOperations, { actionInfo: profileIdsToDelete.length.toString() });
    }

    const profilesForLeave = getSelectedProfilesByAction('leave');
    if (profilesForLeave.length) {
      promises.push(await removeMultipleInvites(profilesForLeave.map((profile: any) => profile.shareId)));
    }

    const removedProfileIds = [...profileIdsToDelete, ...profilesForLeave.map(profile => profile.id)];
    const profileSettingsState = getProfilesSettingsState();
    if (removedProfileIds.find(removedProfileId => profileSettingsState.profileIds.includes(removedProfileId))) {
      closeProfilesSettings();
    }

    await Promise.all(promises).finally(() => changeProcessing(false));
    if (profileIdsToDelete.length) {
      message.success(translation('tableProfiles.notification.profilesDeleted', { count: profileIdsToDelete.length }));
    }

    resetProfilesTableSelectedIds();
    await props.updateProfilesList();
  };

  const cloneProfile = async () => {
    sendActionAnalytics('clicked clone profile via mass operations');

    let second = 2000;
    const profile = selectedProfilesIds.length;
    const selectedFolder = (!NEW_FEATURES.header && props.selectedFolder === 'all') ? '' : props.selectedFolder;
    if (NEW_FEATURES.workspaces) {
      await cloneWorkspaceProfiles(workspaceId, selectedProfilesIds);
    } else {
      await cloneProfileMulti(selectedProfilesIds, selectedFolder);
    }

    switch (true) {
      case (profile > 4 && profile < 11):
        second = 4000;
        break;
      case (profile > 11):
        second = 5000;
        break;
      default:
    }

    message.success(translation('notifications.success.profileCloned'));
  };

  const getLoader = () => {
    if (!isProcessing) {
      return null;
    }

    return (
      <LoaderDiv>
        <Icon type='loading' />
      </LoaderDiv>
    );
  };

  const exportProfile = async () => {
    if (NEW_FEATURES.workspaces) {
      const selectedProfileIds = getProfilesTableSelectedIds();
      const canExportProfiles = filterProfilesByPermission(selectedProfileIds, 'exportProfile');
      if (!canExportProfiles && (workspaceIsUnpaid || workspaceIsTrial)) {
        setShowExportPayPopUp(true);

        return;
      } else if (!canExportProfiles) {
        message.error(translation('notifications.error.permissionWorkspace'));

        return;
      }
    } else if (!hasSuccessPayment || hasTrial) {
      setShowExportPayPopUp(true);

      return;
    }

    changeProcessing(true);

    let response;
    if (NEW_FEATURES.workspaces) {
      response = await exportWorkspaceProfiles(workspaceId, selectedProfilesIds).catch(() => null);
    } else {
      response = await profilesExport(selectedProfilesIds).catch(() => null);
    }

    if (!response) {
      changeProcessing(false);

      return;
    }

    const saveFileElement = document.createElement('a');
    const outputFile = new Blob(['\ufeff', response], { type: 'text/plain' });
    const date = moment().utc().format('YYYYMMDD');

    saveFileElement.href = URL.createObjectURL(outputFile);
    saveFileElement.download = `gologin_profiles_export_${date}.csv`;

    saveFileElement.click();

    changeProcessing(false);
  };

  const redirectToSite = async (): Promise<void> => {
    setShowExportPayPopUp(false);
    openWebsitePricing({
      workspaceId,
      isQuickSettingsEnabled,
      isShowPaymentMethods: true,
    });
  };

  const updateFingerprints = async () => {
    changeProcessing(true);
    const resolution = `${screen.width}x${screen.height}`;

    const newProfilesData = await updateMultipleFingerprints({
      resolution,
      instanceIds: selectedProfilesIds,
      template: selectedTemplate.id,
    }).finally(() => changeProcessing(false));

    props.updateProfileFingerprints(newProfilesData);
    message.success(translation('notifications.success.fingerprintsUpdated'));
  };

  const onRenameProfiles = (): void => {
    openProfilesSettingsOfSelectedIds();
    setManyProfilesName(selectedTemplate.profileName.rename || DEFAULT_MANY_PROFILE_NAME);
    if (isProfilesSettingsOpen) {
      setNeedOpenSpintaxPopup(true);

      return;
    }

    document.getElementById('pinned-name-param-profile-name')?.click();
  };

  const limitForLaunch = (multipleLaunched || runningProfilesCount >= 5) ? 5 : 10;
  const isStartDisabled = selectedProfilesIds.length > limitForLaunch;

  const getBrowserMajorVersionCurrent = (userAgent = ''): string => {
    const version = getBrowserVersionCurrent(userAgent);
    const [majorVersion] = version.split('.');

    return majorVersion;
  };

  const filterOrbitasVersionsAvailable = async (majorVersions: number[]): Promise<number[]> =>
    ipcRenderer.invoke('check-orbitas-available', majorVersions);

  const isProfileVersionLoading = (profile: IProfile): boolean => {
    const chromeVersionMatch = profile.navigator.userAgent.match(/[\s\S\w]+Chrome\/(\d+)/) || [];
    if (!chromeVersionMatch) {
      return true;
    }

    const [_, version = ''] = chromeVersionMatch;

    return Number(props.versionLoadingNow) === Number(version);
  };

  const isProfileAvailableToRun = (profile: IProfile): boolean => {
    if (!selectedProfilesIds.includes(profile.id)) {
      return false;
    }

    const profileRunStatus = getProfileRunStatus(profile.id);

    return profile.canBeRunning &&
      !(profileRunStatus.isWeb || profileRunStatus.status === 'profileStatuses.running' || isProfileVersionLoading(profile));
  };

  const getAvailableToRunProfiles = async (profiles: IProfile[], transaction?: Sentry.Transaction): Promise<IProfile[]> => {
    const span = transaction?.startChild({ op: 'check-browser-version-before-launch-mass-run' });
    let availableToRunProfiles = profiles.filter(isProfileAvailableToRun);
    if (isElectron) {
      const profilesMajorVersions = availableToRunProfiles.map((profile) => +getBrowserMajorVersionCurrent(profile.navigator.userAgent));
      const profilesMajorVersionsUnique = [...new Set(profilesMajorVersions)];
      const orbitasVersionsAvailable = await filterOrbitasVersionsAvailable(profilesMajorVersionsUnique);

      availableToRunProfiles = availableToRunProfiles.filter((profile) => {
        const majorVersion = +getBrowserMajorVersionCurrent(profile.navigator.userAgent);

        return orbitasVersionsAvailable.includes(majorVersion);
      });
    }

    span?.finish();

    return availableToRunProfiles;
  };

  const startProfiles = async (): Promise<void> => {
    const transaction = Sentry.startTransaction({ name: 'prepare-mass-run' });

    const spanUpdateState = transaction.startChild({ op: 'update-state' });
    setMultipleLaunched(true);
    spanUpdateState.finish();
    sendActionAnalytics('launched profile via mass operations');
    const profilesList = getProfilesList();
    const profilesSelected = profilesList.filter(({ id }) => selectedProfilesIds.includes(id));
    const [availableToRunProfiles, iconsStartDataMassOps] = await Promise.all([
      getAvailableToRunProfiles(profilesList, transaction),
      generateBrowserIconsMassOpsPromise(profilesSelected, transaction),
    ]);

    transaction.finish();

    availableToRunProfiles.forEach((profile) => launchProfileOrbita({
      profile,
      multipleLaunch: true,
      updateStatusToSync: false,
      skipOrbitaVersionCheck: true,
      iconsStartData: iconsStartDataMassOps[profile.id],
    }).catch(() => null));
  };

  const stopProfiles = () => {
    getProfilesList().forEach((profile) => {
      if (!selectedProfilesIds.includes(profile.id)) {
        return;
      }

      const profileRunStatus = getProfileRunStatus(profile.id);
      if (profileRunStatus.isWeb && profileRunStatus.status === 'profileStatuses.running') {
        stopProfileWeb(profile.id, true);

        return;
      }

      stopProfileOrbita(profile.id);
    });
  };

  const getExtensionsModal = () => (
    <ExtensionsModal
      extensionsModalVisible={extensionsModalVisible}
      setExtensionsModalVisible={setExtensionsModalVisible}
      profileIds={selectedProfilesIds}
      updateProfileInfo={(newProfilesData: Partial<IProfile>): void => props.changeProfilesData(selectedProfilesIds, newProfilesData)}
    />
  );

  const openExtensionsModal = () => {
    setExtensionsModalVisible(true);
  };

  const openBookmarksManager = (): void => {
    setIsShowBookmarksManager(true);
  };

  const buttonMassRun = (disabled: boolean, onClickAction?: () => void): JSX.Element => (
    <MassActionsButtonLine
      onClick={onClickAction}
      disabled={disabled}
    >
      <MassActionsRow>
        <MassActionsButtonText>
          {translation('profiles.multipleOperations.run')}
        </MassActionsButtonText>
      </MassActionsRow>
    </MassActionsButtonLine>
  );

  const renderButtonMassRun = (disabled: boolean, onClickAction?: () => void): JSX.Element => {
    if (disabled) {
      return (
        <div
          onMouseEnter={(event): void => setAnchorElTooltip(event.currentTarget)}
          onMouseLeave={(): void => setAnchorElTooltip(null)}
        >
          {buttonMassRun(disabled, onClickAction)}
        </div>
      );
    }

    return buttonMassRun(disabled, onClickAction);
  };

  const handleEditTagsClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    setAnchorEditTagsEl(anchorEditTagsEl ? null : event.currentTarget);
  };

  return (
    <>
      <MassActionsBlockLine
        style={{ backgroundColor: NEW_FEATURES.header ? 'var(--FFFFFF)' : 'var(--F6F7F9-profile-table-profiles-menu)' }}
        isVisible={!!selectedProfilesIds?.length}
        isProxyManagerVisible={!!editModeProfileId}
      >
        {NEW_FEATURES.header ? (
          <MultipleOperationsMenu
            isBrowserUpdating={props.isBrowserUpdating}
            isProcessing={isProcessing}
            openFoldersModal={props.openFoldersModal}
            openBookmarksManager={openBookmarksManager}
            openExtensionsModal={openExtensionsModal}
            cloneProfile={cloneProfile}
            updateFingerprints={updateFingerprints}
            deleteOrLeaveMultipleProfiles={deleteOrLeaveMultipleProfiles}
            onRenameProfiles={onRenameProfiles}
            redirectToSite={redirectToSite}
            isStartDisabled={isStartDisabled}
            stopProfiles={stopProfiles}
            startProfiles={startProfiles}
            limitForLaunch={limitForLaunch}
            setIsShowProxyManager={setIsShowProxyManager}
            getLoader={getLoader}
            exportProfile={exportProfile}
            setAnchorElTooltip={setAnchorElTooltip}
            handleEditTagsClick={handleEditTagsClick}
          />
        ) : (
          <MassActionsMarginBlock isProfilesSettingsOpen={isProfilesSettingsOpen}>
            <MassActionsButtonLine
              disabled={isProcessing}
              onClick={(): void => openOldOrNewShareModal(getProfilesTableSelectedIds())}
            >
              <MassActionsRow>
                <IconAddMembersGreen />
                <MassActionsButtonText>
                  {translation('profiles.multipleOperations.share')}
                </MassActionsButtonText>
              </MassActionsRow>
            </MassActionsButtonLine>
            <MassActionsButtonLine
              disabled={isProcessing}
              onClick={(): void => props.openFoldersModal(selectedProfilesIds)}
            >
              <MassActionsRow>
                <IconAddFolder />
                <MassActionsButtonText>
                  {translation('profiles.multipleOperations.addToFolder')}
                </MassActionsButtonText>
              </MassActionsRow>
            </MassActionsButtonLine>
            <MassActionsButtonLine
              disabled={isProcessing}
              onClick={openBookmarksManager}
            >
              <MassActionsRow>
                <span>
                  {translation('profilesTable.multipleOperations.bookmarks')}
                </span>
              </MassActionsRow>
            </MassActionsButtonLine>
            <MassActionsButtonLine
              disabled={isProcessing}
              onClick={openTransferModal}
            >
              <MassActionsRow>
                <MassActionsButtonText>
                  {translation('profiles.multipleOperations.transfer')}
                </MassActionsButtonText>
              </MassActionsRow>
            </MassActionsButtonLine>
            <MassActionsButtonLine
              disabled={isProcessing}
              onClick={openExtensionsModal}
            >
              <MassActionsRow>
                <span>
                  {translation('profiles.multipleOperations.extensions')}
                </span>
              </MassActionsRow>
            </MassActionsButtonLine>
            <MassActionsButtonLine
              onClick={cloneProfile}
              disabled={isProcessing}
            >
              <MassActionsRow>
                <Icon type='copy' style={{ fontSize: 18 }} />
                <MassActionsButtonText>
                  {translation('profiles.menu.clone')}
                </MassActionsButtonText>
              </MassActionsRow>
            </MassActionsButtonLine>
            <MassActionsButtonLine
              onClick={handleEditTagsClick}
              disabled={isProcessing}
            >
              <MassActionsRow>
                <MassActionsButtonText>
                  {translation('tags.editTagsBtnText')}
                </MassActionsButtonText>
              </MassActionsRow>
            </MassActionsButtonLine>
            <Popconfirm
              placement='bottom'
              title={translation('profiles.multipleOperations.confirms.newFingerprints')}
              onConfirm={updateFingerprints}
              okText={translation('base.yes')}
              cancelText={translation('base.no')}
              disabled={isProcessing}
            >
              <MassActionsButtonLine
                disabled={isProcessing}
              >
                <MassActionsRow>
                  <IconFinger />
                  <MassActionsButtonText>
                    {translation('profiles.multipleOperations.updateFingerprint')}
                  </MassActionsButtonText>
                </MassActionsRow>
              </MassActionsButtonLine>
            </Popconfirm>
            <Popconfirm
              placement='bottom'
              title={translation('profiles.multipleOperations.confirms.exportProfilesUpgradePlan')}
              onConfirm={redirectToSite}
              onCancel={(): void => setShowExportPayPopUp(false)}
              cancelText={translation('base.cancel')}
              okText={translation('personalArea.billing.changePlanSubmitBtnText')}
              visible={showExportPayPopUp}
            >
              <MassActionsButtonLine
                onClick={exportProfile}
                disabled={isProcessing}
              >
                <MassActionsRow>
                  <Icon type='export' style={{ fontSize: 18 }} />
                  <MassActionsButtonText>
                    {translation('base.export')}
                  </MassActionsButtonText>
                  {getLoader()}
                </MassActionsRow>
              </MassActionsButtonLine>
            </Popconfirm>

            <MassActionsButtonLine
              onClick={() => setIsShowProxyManager(true)}
              disabled={isProcessing}
            >
              <MassActionsRow>
                <span>
                  {translation('proxy.title')}
                </span>
              </MassActionsRow>
            </MassActionsButtonLine>

            <Popconfirm
              placement='bottom'
              title={translation('profiles.multipleOperations.confirms.deleteProfiles')}
              onConfirm={deleteOrLeaveMultipleProfiles}
              okText={translation('base.yes')}
              cancelText={translation('base.no')}
              disabled={isProcessing}
            >
              <MassActionsButtonLineDanger
                disabled={isProcessing}
                onClick={(): Promise<void> => sendActionAnalytics(E_ANALYTICS_ACTIONS.clickedDeleteProfilesMassOperations)}
              >
                <MassActionsRow>
                  <IconDeleteProfile />
                  <MassActionsButtonText>
                    {selectedProfilesActions.length === 2 &&
                    <>
                      {`${translation('base.delete')} / ${translation('base.leave')}`}
                    </>}
                    {selectedProfilesActions.length === 1 &&
                    <>
                      {translation(`base.${selectedProfilesActions[0].toLowerCase()}`)}
                    </>}
                    {selectedProfilesActions.length === 0 &&
                    <>
                      {translation('base.delete')}
                    </>}
                  </MassActionsButtonText>
                </MassActionsRow>
              </MassActionsButtonLineDanger>
            </Popconfirm>
            {isElectron ? (
              <>
                {!props.isBrowserUpdating && isStartDisabled
                  ? (
                    <Popconfirm
                      title={translation('profiles.multipleOperations.confirms.startProfilesFirstPart') + limitForLaunch + translation('profiles.multipleOperations.confirms.startProfilesSecondPart')}
                      placement='bottom'
                      onConfirm={startProfiles}
                      okText={translation('base.yes')}
                      cancelText={translation('base.no')}
                    >
                      {renderButtonMassRun(props.isBrowserUpdating)}
                    </Popconfirm>
                  )
                  : renderButtonMassRun(props.isBrowserUpdating, startProfiles)}
              </>
            ) : null}
            <MassActionsButtonLineDanger
              disabled={isProcessing}
              onClick={stopProfiles}
            >
              <MassActionsRow>
                <Icon type='poweroff' style={{ fontSize: 18 }} />
                <MassActionsButtonText>
                  {translation('profiles.multipleOperations.stop')}
                </MassActionsButtonText>
              </MassActionsRow>
            </MassActionsButtonLineDanger>
            {NEW_FEATURES.header ? (
              <MassActionsButtonLine disabled={isProcessing} onClick={onRenameProfiles}>
                <MassActionsRow>
                  <MassActionsButtonText>
                    {translation('profiles.multipleOperations.renameProfiles', { count: selectedProfilesIds.length })}
                  </MassActionsButtonText>
                </MassActionsRow>
              </MassActionsButtonLine>
            ) : null}
            <MassActionsBlockEnd>
              <Icon
                type='close'
                style={{ cursor: 'pointer', color: 'var(--00000073-antd)', marginLeft: 16 }}
                onClick={(): void => resetProfilesTableSelectedIds()}
              />
            </MassActionsBlockEnd>
          </MassActionsMarginBlock>
        )}
        <MultipleProxyManager
          onClose={(): void => setIsShowProxyManager(false)}
          visible={isShowProxyManager}
        />
      </MassActionsBlockLine>
      {getExtensionsModal()}
      <ProfileBookmarksModal
        visible={isShowBookmarksManager}
        onClose={() => setIsShowBookmarksManager(false)}
        profilesIds={selectedProfilesIds}
      />
      <ImportDolphinProfilesModal
        visible={isShowImportDolphinModal}
        onClose={() => setIsShowImportDolphinModal(false)}
      />
      <EditTagsMenu
        selectedRows={selectedProfilesIds}
        updateTag={updateTag}
        addTag={addTag}
        removeProfileTag={removeProfileTag}
        removeTag={removeTag}
        anchorEditTagsEl={anchorEditTagsEl}
        setAnchorEditTagsEl={setAnchorEditTagsEl}
      />
      <TooltipCustom
        anchorEl={anchorElTooltip}
        value={translation('notifications.error.orbitaIsDownloading')}
      />
    </>
  );
};

export default ProfilesMenu;
