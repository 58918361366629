import styled from '@emotion/styled';
import { Popover } from '@material-ui/core';
import { Dropdown, Icon, Menu, message, Tooltip } from 'antd';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { userContext } from '../../../state';
import {
  closeProfileTableModal,
  OLD_SHARE_MODAL_NAME,
  useProfilesTableModalProfiles,
  useProfileTableModalIsVisible,
} from '../../../state/profiles-table-modal.atom';
import { updateProfilesTableSelectedIds } from '../../../state/profiles-table-selected-ids.atom';
import { IconSpinner } from '../../../ui/gologin-header/icons';
import GologinModal from '../../../ui/gologin-modal';
import {
  removeProfileInvite,
  requestProfileInvites,
  shareMultipleProfilesToOneUser,
  shareProfile,
  updateInviteRole,
} from '../../quickProfiles/api';

const Col = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 150px;
`;

const Row = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
`;

const IconSharing = styled (Icon)`
  font-size: 9px;
  margin-top: 2px;
  opacity: 0.6; 
  color: var(--000000);
  margin-left: 3px;
`;

const IconUser = styled (Icon)`
  font-size: 20px;
  opacity: 0.6; 
  color: var(--000000);
`;

const TextSharing = styled('span')`
  font-family: Roboto;
  font-size: 12px;
  line-height: 14px;
  color: var(--000000);
  opacity: 0.6;
  white-space: nowrap;
  justify-content: flex-end;
`;

const RowSpaceBetween = styled(Row)`
  justify-content: space-between;
`;

const UserIconContainer = styled('div')`
  margin-right: 5px;
`;

const CloseIconContainer = styled('div')`
  margin-left: 5px;
  cursor: pointer;
  min-width: 19px;
`;

const UserContainer = styled('div')`
  margin: 10px 0;
`;

const ButtonSharing = styled('button')`
  position: relative;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  padding: 0 15px;
  border-radius: 4px;
  outline: 0;
  border: 1px solid var(--00B86D);
  background-color: var(--FFFFFF-antd);
  font-family: Roboto;
  font-size: 11px;
  line-height: 13px;
  color: var(--00B86D);
  width: 85px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;

  :hover{
    opacity: 0.7
  }
`;

const TextEmail = styled('div')`
  font-size: 12px;
  margin-left: 10px;
`;

const InputEmail = styled('input')`
  height: 30px;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 324px;
  padding: 4px 11px;
  font-size: 14px;
  line-height: 1.5;
  background-image: none;
  border-radius: 4px;
  transition: all 0.3s;
  background-color: var(--FFFFFF-antd);
  border: 1px solid var(--D9D9D9-antd);
  color: var(--000000A6-antd);
  
  :hover {
    border-color: var(--1FC47D-antd);
  }
  
  :focus {
    border-color: var(--1FC47D-antd);
    box-shadow: 0 0 0 2px var(--00B86D33-antd);
  }
  
  ::placeholder {
    color: var(--BFBFBF-antd);
  }
`;

const MenuPopup = styled(Popover)`
  .MuiPopover-paper {
    background-color: var(--FFFFFF-antd);
    margin: 0;
    padding: 4px 0;
    text-align: left;
    list-style-type: none;
    background-clip: padding-box;
    border-radius: 4px;
    outline: none;
    box-shadow: var(--box-shadow);
  }
`;

const OptionItem = styled.div`
  clear: both;
  margin: 0;
  padding: 5px 12px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
  color: var(--000000A6-antd);
  
  :hover {
    background-color: var(--DFF7E9-antd);
  }
`;

declare interface IUser {
  email: string;
  role: string;
  inviteId: string;
}

const ShareModal = (): JSX.Element => {
  const [loadingInvites, changeInvitesLoading] = useState(true);
  const [invites, setInvites] = useState<any[]>([]);
  const [anchorElSearchMenu, setAnchorElSearchMenu] = useState<HTMLElement|null>(null);
  const [selectedRole, changeSelectedRole] = useState<'guest'|'redactor'|'administrator'|'owner'>('guest');

  const { hasTrial, plan } = useContext(userContext);

  // Куда он inserted? я могу его вписать в десяток мест
  const [insertedEmail, changeInsertedEmail] = useState<string>('');

  const isModalVisible = useProfileTableModalIsVisible(OLD_SHARE_MODAL_NAME);
  const modalProfiles = useProfilesTableModalProfiles();

  const { t: translation } = useTranslation();

  const profileIdsToShare = modalProfiles.map(profile => profile.id);
  const [firstProfileToShare = { id: '' }] = modalProfiles;
  const firstProfileToShareId = firstProfileToShare.id;
  const isShareMultipleProfiles = modalProfiles.length >= 2;

  const getInvites = async () => {
    if (isShareMultipleProfiles || !firstProfileToShareId) {
      changeInvitesLoading(false);

      return;
    }

    const profiles = await requestProfileInvites(firstProfileToShareId);
    setInvites(profiles);
    changeInvitesLoading(false);
  };

  useEffect(() => {
    getInvites();
  }, [firstProfileToShare]);

  const removeInvite = async (id: string) => {
    changeInvitesLoading(true);
    await removeProfileInvite(id)
      .then(() => updateProfilesTableSelectedIds([firstProfileToShareId]))
      .finally(() => changeInvitesLoading(false));
    getInvites();
  };

  const changeUserRole = async (id: string, role: 'administrator'|'redactor'|'guest') => {
    await updateInviteRole(id, role)
      .finally(() => changeInvitesLoading(false));
  };

  const sendInvite = async () => {
    if (!insertedEmail) {
      message.error(translation('errorMessages.insertUserEmail'));

      return;
    }

    changeInvitesLoading(true);

    await new Promise((resolve) => {
      if (isShareMultipleProfiles) {
        resolve(shareMultipleProfilesToOneUser(profileIdsToShare, selectedRole, insertedEmail));

        return;
      }

      resolve(shareProfile(firstProfileToShareId, selectedRole, insertedEmail));
    })
      .then(() => {
        if (isShareMultipleProfiles) {
          closeProfileTableModal();
        }

        changeInsertedEmail('');
      })
      .finally(() => {
        changeInvitesLoading(false);
      });

    if (!isShareMultipleProfiles) {
      getInvites();
    }
  };

  const User: FC<IUser> = (props) => {
    const [GetRole, changeGetRole] = useState(props.role);

    const { email } = useContext(userContext);

    const showIcon = () => {
      if (props.role === 'owner') {

      } else {
        return (
          <IconSharing type='down' style={{ paddingTop:2 }} />
        );
      }
    };

    const changeInviteText = () => {
      if (GetRole === 'guest') {
        return translation('roles.profile.guest.subtitle');
      }

      if (GetRole === 'owner') {
        return translation('roles.profile.owner.subtitle');
      }

      if (GetRole === 'redactor') {
        return translation('roles.profile.editor.subtitle');
      }

      return translation('roles.profile.administrator.subtitle');
    };

    const menuChangeRole = (
      <Menu>
        <Menu.Item
          onClick={(e: any) => {
            changeGetRole(e.key);
            changeUserRole(props.inviteId, e.key);
            getInvites();
          }}
          key='guest'
        >
          {translation('roles.profile.guest.title')}
        </Menu.Item >
        <Menu.Item
          onClick={(e: any) => {
            changeGetRole(e.key);
            changeUserRole(props.inviteId, e.key);
            getInvites();
          }}
          key='redactor'
        >
          {translation('roles.profile.editor.title')}
        </Menu.Item>
        <Menu.Item
          onClick={(e: any) => {
            changeGetRole(e.key);
            changeUserRole(props.inviteId, e.key);
            getInvites();
          }}
          key='administrator'
        >
          {translation('roles.profile.administrator.title')}
        </Menu.Item>
      </Menu>
    );

    return (
      <UserContainer>
        <RowSpaceBetween>
          <Row>
            <UserIconContainer>
              <IconUser type='user' />
            </UserIconContainer>
            <TextEmail>
              {props.email}
            </TextEmail>
          </Row>
          <Dropdown overlay={menuChangeRole} trigger={['click']} disabled={props.role === 'owner' || props.email === email}>
            <span style={{ cursor: 'pointer', display: 'flex' }}>
              <TextSharing style={{ marginLeft: 15, marginTop: 2 }}>
                {changeInviteText()}
              </TextSharing>
              {showIcon()}
            </span>
          </Dropdown>
          <CloseIconContainer>
            {(props.role !== 'owner' && props.email !== email) && <Icon type='delete' onClick={() => removeInvite(props.inviteId)} />}
          </CloseIconContainer>
        </RowSpaceBetween>
      </UserContainer>
    );
  };

  const renderInviteText = () => {
    if (selectedRole === 'guest') {
      return translation('roles.profile.guest.title');
    }

    if (selectedRole === 'redactor') {
      return translation('roles.profile.editor.title');
    }

    if (selectedRole === 'owner') {
      return translation('roles.profile.owner.title');
    }

    return translation('roles.profile.administrator.title');
  };

  const openPopup = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => setAnchorElSearchMenu(event.currentTarget);

  const closePopup = (): void => setAnchorElSearchMenu(null);

  return (
    <GologinModal
      isVisible={isModalVisible}
      onClose={closeProfileTableModal}
      title={translation('shareModal.title')}
      width='520px'
    >
      <Col>
        <RowSpaceBetween style={{ marginBottom: 16 }}>
          <InputEmail
            style={{
              height: 30,
            }}
            type='email'
            placeholder={translation('shareModal.form.emailInput.placeholder')}
            value={insertedEmail}
            onChange={(event): void => changeInsertedEmail(event.target.value.trim())}
          />
          <span onClick={openPopup} style={{ cursor: 'pointer', display: 'flex' }}>
            <TextSharing style={{ marginLeft: 15, marginTop: 2 }}>
              {renderInviteText()}
            </TextSharing>
            <div style={{ marginTop: -3 }}>
              <IconSharing type='down' />
            </div>
          </span>
          <MenuPopup
            open={!!anchorElSearchMenu}
            anchorEl={anchorElSearchMenu}
            onClose={closePopup}
            transitionDuration={0}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            style={{ zIndex: 1150 }}
          >
            <OptionItem onClick={(): void => changeSelectedRole('guest')}>
              {translation('roles.profile.guest.title')}
            </OptionItem >
            <OptionItem onClick={(): void => changeSelectedRole('redactor')}>
              {translation('roles.profile.editor.title')}
            </OptionItem>
            <OptionItem onClick={(): void => changeSelectedRole('administrator')}>
              {translation('roles.profile.administrator.title')}
            </OptionItem>
            {(!isShareMultipleProfiles && !hasTrial && plan?.priceForMonth) ? <OptionItem onClick={(e: any) => changeSelectedRole(e.key)} key='owner'>
              <Tooltip title={translation('roles.profile.owner.help')}>
                {translation('roles.profile.owner.title')}
              </Tooltip>
            </OptionItem> : null}
          </MenuPopup>
          <ButtonSharing onClick={sendInvite}>
            {translation('shareModal.form.submitBtnText')}
          </ButtonSharing>
        </RowSpaceBetween>
        {loadingInvites ? <div style={{ width: '100%', fontSize: 24, textAlign: 'center' }}>
          <IconSpinner
            size={24}
            padding={0}
          />
        </div> : null}
        {invites.map(i => (
          <>
            <User
              email={i.to.email}
              role={i.role}
              inviteId={i.id}
              key={i.id}
            />
          </>
        ))}
      </Col>
    </GologinModal>
  );
};

export default ShareModal;
