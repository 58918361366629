import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { setProxiesTableList } from './proxy-table/proxies-table-list.atom';
import { getIsProxyArchived } from '../../features/proxy/proxy-helpers';
import { PROXY_ARTIFICIAL_ID_SEPARATOR } from '../../features/proxy/utils/proxy-id';
import { IProxy } from '../../interfaces';

export const proxyListAtom = atom<IProxy[]>([]);
const isProxyListLoadedAtom = atom<boolean>(false);

const setProxyList = (proxies: IProxy[]): void => getDefaultStore().set(proxyListAtom, proxies);

export const getProxyList = (): IProxy[] => getDefaultStore().get(proxyListAtom);
export const setIsProxyListLoaded = (value: boolean): void => getDefaultStore().set(isProxyListLoadedAtom, value);

export const useProxyList = (): IProxy[] => useAtomValue(proxyListAtom);
export const useIsProxyListLoaded = (): boolean => useAtomValue(isProxyListLoadedAtom);

export const updateProxyList = (proxies: IProxy[]): void => {
  const notArchivedProxies = proxies.filter(proxy => !getIsProxyArchived(proxy));
  setProxyList(notArchivedProxies);
};

export const unshiftManyProxies = (newProxies: IProxy[]): void => {
  const proxyList = getProxyList();
  updateProxyList([...newProxies, ...proxyList]);
};

export const pushManyProxies = (newProxies: IProxy[]): void => {
  const proxyList = getProxyList();
  updateProxyList([...proxyList, ...newProxies]);
};

export const removeProxiesFromList = (proxyIdsToDelete: string[]): void => {
  const proxyList = getProxyList();
  setProxyList(proxyList.filter(listedProxy => !proxyIdsToDelete.includes(listedProxy.id)));
};

export const upsertOneProxy = (newProxy: IProxy): void => {
  const proxyList = getProxyList();
  let hasProxyChanged = false;
  const updatedProxies = proxyList.map((listedProxy) => {
    if (listedProxy.id !== newProxy.id) {
      return listedProxy;
    }

    hasProxyChanged = true;
    newProxy = {
      ...listedProxy,
      ...newProxy,
      profilesCount: listedProxy.profilesCount,
    };

    return newProxy;
  });

  if (!hasProxyChanged) {
    updatedProxies.unshift({ ...newProxy, checkInProgress: true });
  }

  updateProxyList(updatedProxies);
};

const updateProxyInList = (proxiesInList: IProxy[], proxyItem: IProxy, isUsedForManyProxies = false): IProxy[]|void => {
  const proxyInListIndex = proxiesInList.findIndex(proxy => proxy.id === proxyItem.id);
  const isInvisible = proxyItem.id && proxyInListIndex === -1 && proxyItem.isInvisible;

  if (isInvisible) {
    const proxyToAdd: IProxy = { ...proxyItem, isInvisible };
    const newProxyList = [...proxiesInList];
    newProxyList.push(proxyToAdd);
    if (isUsedForManyProxies) {
      return newProxyList;
    }

    return updateProxyList(newProxyList);
  }

  const newProxyList = [...proxiesInList];
  if (proxyInListIndex > -1) {
    newProxyList[proxyInListIndex] = { ...newProxyList[proxyInListIndex], ...proxyItem };
  } else {
    // proxy needs to be updated after check,
    // if it is not listed (for various reasons such as profile transferred with it)
    newProxyList.push({
      ...proxyItem,
      id: proxyItem.id || '',
      profilesCount: 0,
      mode: proxyItem.mode || 'http',
      host: proxyItem.host || '',
      port: proxyItem.port || 80,
      isInvisible: true,
    });
  }

  if (isUsedForManyProxies) {
    return newProxyList;
  }

  updateProxyList(newProxyList);
};

export const updateProxyItem = (proxyItem: IProxy): void => {
  if (getIsProxyArchived(proxyItem)) {
    return;
  }

  const proxyList = getProxyList();
  updateProxyInList(proxyList, proxyItem);
};

export const updateProxyItems = (proxyItems: IProxy[]): void => {
  const proxyList = getProxyList();
  const newProxyList = proxyItems.reduce<IProxy[]>((newProxyListAcc, proxyItem) => {
    const newList = updateProxyInList(proxyList, proxyItem, true);
    if (newList) {
      newProxyListAcc = [...newList];
    }

    return newProxyListAcc;
  }, [...proxyList]);

  updateProxyList(newProxyList);
};

export const clearProxyLists = (): void => {
  setProxyList([]);
  setProxiesTableList([]);
};

export const incrementProxyProfilesCount = (proxyId?: string): void => {
  if (!proxyId) {
    return;
  }

  const proxyList = getProxyList();

  const newProxiesList = proxyList.map((listedProxy) => {
    if (listedProxy?.id === proxyId) {
      listedProxy.profilesCount ? listedProxy.profilesCount += 1 : listedProxy.profilesCount = 1;
    }

    return listedProxy;
  });

  updateProxyList(newProxiesList);
};

export const decrementProxyProfilesCount = (proxyId?: string): void => {
  if (!proxyId) {
    return;
  }

  const proxyList = getProxyList();

  const updatedProxies = proxyList.map((listedProxy) => {
    if (listedProxy.id === proxyId && listedProxy.profilesCount) {
      listedProxy.profilesCount -= 1;
    }

    return listedProxy;
  });

  updateProxyList(updatedProxies);
};

export const useProxyListProxyById = (proxyId: string | null): IProxy | null => {
  const proxyList = useProxyList();
  if (!proxyId) {
    return null;
  }

  return proxyList.find(listedProxy => listedProxy.id === proxyId) || null;
};

export const getProxyListProxyById = (proxyId?: string): IProxy | null => {
  const proxyList = getProxyList();

  return proxyList.find(listedProxy => listedProxy.id === proxyId) || null;
};

export const updateProxyInListById = (
  proxyId: string,
  partialUpdate: Partial<IProxy>,
): void => {
  const proxyList = getProxyList();
  updateProxyList(proxyList.map((listedProxy) => {
    if (listedProxy.id !== proxyId) {
      return listedProxy;
    }

    return { ...listedProxy, ...partialUpdate };
  }));
};

export const getProxyByArtificialProxyId = (artificialProxyId: string | null): IProxy | null => {
  if (!artificialProxyId) {
    return null;
  }

  const proxyList = getProxyList();
  const [mode, region] = artificialProxyId.split(PROXY_ARTIFICIAL_ID_SEPARATOR);
  if (!(mode && region)) {
    return null;
  }

  const field: keyof IProxy = mode === 'gologin' ? 'autoProxyRegion' : 'torProxyRegion';

  return proxyList.find(proxy => proxy.mode === mode && proxy[field] === region) || null;
};

export const resetCustomlySortedProxies = (): void => {
  const proxyList = getProxyList();
  const proxyListWithoutCustomSorting = proxyList.reduce<IProxy[]>((acc, proxy) => {
    if (proxy.customSortingInGroup) {
      delete proxy.customSortingInGroup;
    }

    acc.push(proxy);

    return acc;
  }, []);

  setProxyList(proxyListWithoutCustomSorting);
};
