import { TRAFFIC_LIMIT } from '../../../app/features/proxy/constants/settings';
import { IGeolocationProxy, IProxyTraffic } from '../../../app/interfaces';
import { PROXY_TRAFFIC_TYPES_MAP } from '../../../app/ui/gologin-header/proxy-traffic-popover/geolocation-proxy-type';
import { GeoProxyType } from '../../constants/types';

const KIBI = 1024;

export const TRAFFIC_UNITS = <const>{
  gb: 'GB',
  mb: 'MB',
};

export type TrafficUnit = typeof TRAFFIC_UNITS[keyof typeof TRAFFIC_UNITS];

type TrafficUsageWithRemainderInBytes = Pick<IProxyTraffic, 'trafficUsedBytes' | 'trafficLimitBytes'> & {
  trafficRemainingBytes: number;
};

export const convertBytes = (bytes: number, unit: TrafficUnit): number => {
  const megabytes = bytes / KIBI / KIBI;
  if (unit === TRAFFIC_UNITS.mb) {
    return megabytes;
  }

  const gigabytes = megabytes / KIBI;

  return gigabytes;
};

export const calculateTrafficUsage = (geoProxyTraffic: IGeolocationProxy, geoProxyType: GeoProxyType): IProxyTraffic =>
  geoProxyTraffic[PROXY_TRAFFIC_TYPES_MAP[geoProxyType]];

export const calculateTrafficUsageWithRemainderInBytes = (
  geoProxyTraffic: IGeolocationProxy,
  geoProxyType: GeoProxyType,
): TrafficUsageWithRemainderInBytes => {
  const { trafficUsedBytes, trafficLimitBytes } = calculateTrafficUsage(geoProxyTraffic, geoProxyType);
  const trafficRemainingBytes = trafficLimitBytes - trafficUsedBytes;

  return { trafficUsedBytes, trafficLimitBytes, trafficRemainingBytes };
};

export const calculateRemainingTrafficLimit = (geoProxyTraffic: IGeolocationProxy, geoProxyType: GeoProxyType): number => {
  const { trafficUsedBytes, trafficLimitBytes } = calculateTrafficUsage(geoProxyTraffic, geoProxyType);
  const trafficRemainingBytes = trafficLimitBytes - trafficUsedBytes;

  if (trafficRemainingBytes <= TRAFFIC_LIMIT) {
    return 0;
  }

  return convertBytes(trafficRemainingBytes, TRAFFIC_UNITS.gb);
};

export const calculateRemainingAllTypesTrafficLimits = (geoProxyTraffic: IGeolocationProxy): [GeoProxyType, number][] =>
  Object.values(GeoProxyType).reduce<[GeoProxyType, number][]>((acc, geoProxyType) => {
    const trafficLimit = calculateRemainingTrafficLimit(geoProxyTraffic, geoProxyType);
    acc.push([geoProxyType, trafficLimit]);

    return acc;
  }, []);

export const calculateRemainingAllTypesTrafficLimitsObject = (geoProxyTraffic: IGeolocationProxy): Record<GeoProxyType, number> =>
  Object.values(GeoProxyType).reduce<Record<GeoProxyType, number>>(
    (acc, geoProxyType) => {
      const trafficLimit = calculateRemainingTrafficLimit(geoProxyTraffic, geoProxyType);
      acc[geoProxyType] = trafficLimit;

      return acc;
    },
    { resident: 0, mobile: 0, dataCenter: 0 },
  );

export const calculateAvailableConnectionTypes = (geoProxyTraffic: IGeolocationProxy, typeToExclude?: GeoProxyType): GeoProxyType[] => {
  const remainingAllTypesTrafficLimits = calculateRemainingAllTypesTrafficLimits(geoProxyTraffic);

  return remainingAllTypesTrafficLimits.reduce<GeoProxyType[]>((acc, [geoProxyType, trafficLimit]) => {
    const isTypeSelectable = (!typeToExclude || geoProxyType !== typeToExclude) && trafficLimit;
    if (isTypeSelectable) {
      acc.push(geoProxyType);
    }

    return acc;
  }, []);
};

export const stringifyTrafficAmount = (trafficAmount: number, fractionDigits = 1): string => {
  const normalizedTrafficAmount = trafficAmount > 0 ? trafficAmount : 0;

  if (fractionDigits === 2 && normalizedTrafficAmount === 0) {
    return '0';
  }

  return normalizedTrafficAmount.toFixed(fractionDigits);
};

export const stringifyRemainingTrafficAmount = (
  geoProxyTraffic: IGeolocationProxy,
  geoProxyType: GeoProxyType,
  shouldChangeFractionDigits = false,
): string => {
  const remainingTrafficLimit = calculateRemainingTrafficLimit(geoProxyTraffic, geoProxyType);

  const isMoreThanOne = remainingTrafficLimit > 1.0;

  return stringifyTrafficAmount(remainingTrafficLimit, isMoreThanOne || !shouldChangeFractionDigits ? 1 : 2);
};
