import styled from '@emotion/styled';
import { Button, message } from 'antd';
import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { updateProfilesUA } from './api';
import { IUpdateProfilesUAOpts, IUpdateProfilesUAResponse } from './interfaces';
import { workspaceContext } from '../../../../state';
import { mapAndSetProfilesList } from '../../../../state/profiles-list.atom';
import {
  closeProfileTableModal,
  UPDATE_PROFILE_MODAL_NAME,
  useProfilesTableModalProfiles,
  useProfileTableModalIsVisible,
} from '../../../../state/profiles-table-modal.atom';
import { GologinCheckbox } from '../../../../ui/gologin-checkbox';
import GologinModal from '../../../../ui/gologin-modal';

const Container = styled('div')`
  font-family: 'Roboto';
  line-height: initial;
`;

const InformationTxt = styled('div')`
  width: 440px;
  margin-bottom: 10px;
  font-weight: 300;
  font-size: 16px;
`;

const CheckboxContainer = styled('div')`
  display: flex;
`;

const CheckboxLabel = styled('span')`
  padding-left: 8px;
  color: var(--000000A6-antd);
  line-height: initial;
  font-size: 16px;
  font-weight: 300;
`;

const UpdateProfilesModal = (): JSX.Element => {
  const [updateAll, setUpdateAll] = useState<boolean>(false);
  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false);

  const { id: workspaceId } = useContext(workspaceContext);

  const isModalVisible = useProfileTableModalIsVisible(UPDATE_PROFILE_MODAL_NAME);
  const [modalProfile] = useProfilesTableModalProfiles();

  const { t: translation } = useTranslation();

  const profileId = modalProfile?.id;

  const updateProfilesUAHandleClick = (updateUaToNewBrowserV = false): void => {
    setButtonsDisabled(true);

    const updateUaParams: IUpdateProfilesUAOpts = {
      browserIds: [profileId],
      updateAllProfiles: updateAll,
      updateUaToNewBrowserV,
      testOrbita: false,
      currentWorkspace: workspaceId,
    };

    updateProfilesUA(updateUaParams)
      .then((res: IUpdateProfilesUAResponse = { updatedTo: '' }) => {
        const { updatedTo } = res;
        if (updateUaToNewBrowserV) {
          message.success(translation('updateProfilesToLatestUseragent.success'));
        }

        mapAndSetProfilesList(prevProfiles => prevProfiles.map((profile) => {
          if (updateAll || profile.id === profileId) {
            delete profile.suggestUpdateUA;

            if (updatedTo) {
              profile.navigator.userAgent = updatedTo;
            }
          }

          return profile;
        }));

        closeProfileTableModal();
      })
      .catch((e: any) => {
        message.error(e.message || e.body?.message);
        setButtonsDisabled(false);
      });
  };

  const onCloseModal = (): void => {
    setButtonsDisabled(false);
    setUpdateAll(false);
    closeProfileTableModal();
  };

  return (
    <GologinModal
      isVisible={isModalVisible}
      onClose={onCloseModal}
      title={translation('updateProfilesToLatestUseragentModal.title')}
      footer={(
        <>
          <Button
            disabled={buttonsDisabled}
            onClick={(): void => updateProfilesUAHandleClick(false)}
          >
            {translation('updateProfilesToLatestUseragentModal.buttons.skip.label')}
          </Button>
          <Button
            type='primary'
            style={{ marginLeft: 8 }}
            disabled={buttonsDisabled}
            onClick={(): void => updateProfilesUAHandleClick(true)}
          >
            {translation('updateProfilesToLatestUseragentModal.buttons.submit.label')}
          </Button>
        </>
      )}
    >
      <Container>
        <InformationTxt>
          {translation('updateProfilesToLatestUseragentModal.outdatedFingerprints')}
        </InformationTxt>
        <CheckboxContainer>
          <GologinCheckbox
            checked={updateAll}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => setUpdateAll(event.target.checked)}
          />
          <CheckboxLabel>
            {translation('updateProfilesToLatestUseragentModal.updateAllProfiles.label')}
          </CheckboxLabel>
        </CheckboxContainer>
      </Container>
    </GologinModal>
  );
};

export default UpdateProfilesModal;
