import { css, SerializedStyles } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';

import BackgroundHighlight from '../background-highlight';

export type typeColorIcon = 'activeGreen' |
  'blue' |
  'green' |
  'lightGray' |
  'darkGray' |
  'black' |
  'grayWithBackground' |
  'activeGrayWithBackground' |
  'activeGray' |
  'gray' |
  'red';

export type typeIcon = 'stroke' | 'fill';

export const highlight = css`
  background: var(--EAEAEB);
`;

export const blue = (type: typeIcon): SerializedStyles => css`
  ${type}: var(--1890FF);
`;

export const red = (type: typeIcon): SerializedStyles => css`
  ${type}: var(--F5222D);
`;

export const activeGreen = (type: typeIcon): SerializedStyles => css`
  ${type}: var(--535358);
  color: var(--535358);

  &:hover {
    ${type}: var(--00A987);
    color: var(--00A987);
  }
`;

export const green = (type: typeIcon): SerializedStyles => css`
  ${type}: var(--00A987);
`;

export const black = (type: typeIcon): SerializedStyles => css`
  ${type}: var(--222222);
`;

export const gray = (type: typeIcon): SerializedStyles => css`
  ${type}: var(--8E8E98);
`;

export const lightGray = (type: typeIcon): SerializedStyles => css`
  ${type}: var(--CDCDCD);
`;

export const darkGray = (type: typeIcon): SerializedStyles => css`
  ${type}: var(--535358);
`;

export const activeGray = (type: typeIcon): SerializedStyles => css`
  ${type}: var(--8E8E98);

  &:hover {
    ${type}: var(--535358);
    color: var(--535358);
  }
`;

export const activeGrayWithBackground = (type: typeIcon): SerializedStyles => css`
  ${activeGray(type)};

  &:hover {
    ${highlight};
  }
`;

export const grayWithBackground = (type: typeIcon): SerializedStyles => css`
  ${type}: var(--535358);
  ${highlight};
`;

export const iconTypes = {
  blue,
  red,
  activeGreen,
  green,
  black,
  grayWithBackground,
  activeGrayWithBackground,
  activeGray,
  gray,
  darkGray,
  lightGray,
};

export interface IIconWrapper {
  margin?: string;
  padding?: number | string;
  onClick?: (e: any) => void;
  children?: React.ReactNode;
  highlight?: boolean;
  styleType?: typeColorIcon;
  iconType?: typeIcon;
  onMouseEnter?: (e: any) => void;
  onMouseLeave?: () => void;
  iconColor?: string;
  iconHoveredColor?: string;
  hoveredHighlight?: boolean;
  size?: number;
  borderRadius?: number;
  strokeWidth?: number;
}

// ${(props): SerializedStyles | string => props.styleType ? iconTypes[props.styleType](props.iconType) : ''}; продумать решение получше
const IconWrapperContainer = styled(BackgroundHighlight)<{
  highlight?: boolean;
  styleType?: typeColorIcon;
  iconType: typeIcon;
  iconColor?: string;
  iconHoveredColor?: string;
  hoveredHighlight?: boolean;
}>`
  svg:not(:root) {
    overflow: visible;
  };

  line-height: 0;

  ${(props): SerializedStyles => css `
    ${props.iconType}: ${props.iconColor};
    ${props.highlight ? highlight : ''};

    &:hover {
      ${props.iconType}: ${props.iconHoveredColor};
      color: var(--535358);
      ${props.hoveredHighlight ? highlight : ''};
    }
  `}
`;

// ${(props): SerializedStyles | string => props.colorIconType ? iconTypes[props.colorIconType](props.iconType || 'stroke') : ''};
export const IconWrapperWithDescription = styled(BackgroundHighlight)<{
  highlight?: boolean;
  colorIconType?: typeColorIcon;
  iconType?: typeIcon;
  iconColor?: string;
  iconHoveredColor?: string;
  hoveredHighlight?: boolean;
  textColor?: string;
  textHoveredColor?: string;
}>`
  svg:not(:root) {
    overflow: visible;
  };

  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;

  line-height: 0;

  ${(props): SerializedStyles => css `
    ${props.iconType}: ${props.iconColor};
    ${props.highlight ? highlight : ''};
    color: ${props.textColor};

    &:hover {
      ${props.iconType}: ${props.iconHoveredColor};
      color: ${props.textHoveredColor};
      ${props.hoveredHighlight ? highlight : ''};
    }
  `}
`;

export const DEFAULT_ICON_SIZE = 16;

export const IconWrapper: React.FC<IIconWrapper> = (
  {
    children,
    onClick,
    margin = '',
    padding = 8,
    highlight = false,
    styleType ,
    iconType = 'stroke',
    onMouseEnter,
    onMouseLeave,
    iconColor,
    iconHoveredColor,
    hoveredHighlight,
    size = DEFAULT_ICON_SIZE,
    borderRadius = 8,
  },
) => (
  <IconWrapperContainer
    style={{
      padding,
      margin,
      cursor: onClick ? 'pointer' : 'inherit',
      fontSize: size,
      borderRadius,
    }}
    highlight={highlight}
    onClick={onClick}
    styleType={styleType}
    iconType={iconType}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    iconColor={iconColor}
    iconHoveredColor={iconHoveredColor}
    hoveredHighlight={hoveredHighlight}
  >
    {children}
  </IconWrapperContainer>
);
