import React from 'react';

import { zeroProfileBookmarks } from '../../../../../electron/constants/zero-profile-bookmarks';
import Notes from '../../../../features/quickProfiles/notes-cell/notes';
import { ISettingParam, ISettingsParamOpts } from '../../interfaces';
import {
  ActiveSessionLock,
  AudioContext,
  BrowserBookmarkSaving,
  BrowserHistorySaving,
  BrowserPlugins,
  Canvas,
  Cookies,
  CustomDns,
  DeviceMemory,
  ExtensionStorage,
  ExternalExtensions,
  Fingerprint,
  FolderList,
  Fonts,
  Geolocation,
  GoogleServices,
  HardwareConcurrency,
  Language,
  LocalStorageAndIndexedDB,
  MaxTouchPoints,
  MediaDevices,
  PasswordSaving,
  ProfileName,
  Proxy,
  Resolution,
  SessionSaving,
  StartUrl,
  Timezone,
  UserAgent,
  WebglMetadata,
  WebRTCPublic,
  WebglImage,
  Bookmarks,
  Extensions,
  MixedProfileName,
} from '../settings';
import { Mixed } from '../settings/mixed/mixed';
import { MixedBookmarks } from '../settings/mixed/mixed-bookmarks';
import { MixedExtensions } from '../settings/mixed/mixed-extensions';
import { MixedProxy } from '../settings/mixed/mixed-proxy';

export const settingsParam = (props: ISettingsParamOpts): ISettingParam => {
  const {
    fullProfile, changeSetting, saveProfileNotes, openFoldersModal,
    cookiesLoading, showModalCookies, updateStateProfile,
    isMultiProfilesSettings, updateProfileFingerprints, changeProfilesData, changeProfilesDataMany,
  } = props;

  const {
    id: profileId, name: profileName, lockEnabled, audioContext, notes, defaultProps = {}, isAutoGenerated, navigator, proxy = {}, os,
    geolocation, storage, timezone, googleServicesEnabled, plugins, canvas, dns, mediaDevices, webGLMetadata, webRTC, startUrl, webglParams,
    fonts: profileFonts, folders, webGL, cookies = [], autoLang = true, bookmarks = zeroProfileBookmarks.roots,
  } = fullProfile;

  const isFreeOrTorProxy = ['gologin', 'tor'].includes(proxy.mode || '');

  const propsProxyComponent = { localProxySelectorLocation: 'drawer' };
  const propsProfileNameComponent = {
    previousProfileName: profileName, changeSetting, defaultProps,
    isAutoGenerated: !!isAutoGenerated, changeProfilesData, localSettingId: 'drawer',
  };

  const propsFolderListComponent = { profileFolders: folders || [], openFoldersModal };
  const propsFingerprint = { resolution: navigator?.resolution, updateStateProfile, updateProfileFingerprints };

  const propsNotes = {
    notes, profileId, saveProfileNotes, location: 'profile-settings' as const,
    isDefaultNotes: !!(isAutoGenerated && defaultProps?.profileNotesIsDefault),
  };

  const propsLanguage = {
    changeSetting, profileLanguage: navigator?.language || '', proxyId: proxy.id || '', profileAutoLang: autoLang, profileId,
    isFreeOrTorProxy,
  };

  const mixedParams = {
    name: <MixedProfileName {...propsProfileNameComponent} />,
    notes: <Mixed />,
    folders: <FolderList {...propsFolderListComponent} />,
    language: <Mixed />,
    proxy: <MixedProxy />,
    timezone: <Mixed />,
    geolocation: <Mixed />,
    fingerprint: <Fingerprint {...propsFingerprint} />,
    cookies: <Mixed />,
    useragent: <Mixed />,
    activeSessionLock: <Mixed />,
    sessionSaving: <Mixed />,
    browserHistorySaving: <Mixed />,
    browserBookmarkSaving: <Mixed />,
    googleServices: <Mixed />,
    passwordSaving: <Mixed />,
    localStorageAndIndexedDB: <Mixed />,
    browserPlugins: <Mixed />,
    extensionStorage: <Mixed />,
    externalExtensions: <Mixed />,
    resolution: <Mixed />,
    deviceMemory: <Mixed />,
    hardwareConcurrency: <Mixed />,
    canvas: <Mixed />,
    maxTouchPoints: <Mixed />,
    audioContext: <Mixed />,
    fonts: <Mixed />,
    mediaDevices: <Mixed />,
    webglImage: <Mixed />,
    webglMetadata: <Mixed />,
    startUrl: <Mixed />,
    customDns: <Mixed />,
    webRTCPublic: <Mixed />,
    bookmarks: <MixedBookmarks />,
    extensions: <MixedExtensions
      changeProfilesDataMany={changeProfilesDataMany}
    />,
  };

  const params = {
    name: <ProfileName {...propsProfileNameComponent} />,
    notes: <Notes {...propsNotes} />,
    folders: <FolderList {...propsFolderListComponent} />,
    language: <Language {...propsLanguage} />,
    proxy: <Proxy {...propsProxyComponent} />,
    timezone: <Timezone {...{ timezone, changeSetting, proxy }} />,
    geolocation: <Geolocation {...{ profileGeolocation: geolocation, changeSetting, proxy, isFreeOrTorProxy }} />,
    fingerprint: <Fingerprint {...propsFingerprint} />,
    cookies: <Cookies {...{ cookiesLoading, cookies, profileId }} />,
    useragent: <UserAgent {...{ changeSetting, profileUserAgent: navigator?.userAgent }} />,
    activeSessionLock: <ActiveSessionLock {...{ lockEnabled, changeSetting }} />,
    sessionSaving: <SessionSaving {...{ changeSetting, session: !!storage.session }} />,
    browserHistorySaving: <BrowserHistorySaving {...{ changeSetting, history: !!storage.history }} />,
    browserBookmarkSaving: <BrowserBookmarkSaving {...{ changeSetting, bookmarks: !!storage.bookmarks }} />,
    googleServices: <GoogleServices {...{ googleServicesEnabled, changeSetting }} />,
    passwordSaving: <PasswordSaving {...{ changeSetting, passwords: !!storage.passwords }} />,
    localStorageAndIndexedDB: <LocalStorageAndIndexedDB {...{ changeSetting, indexedDb: !!storage.indexedDb, local: !!storage.local }} />,
    browserPlugins: <BrowserPlugins {...{ enableVulnerable: plugins.enableVulnerable, changeSetting }} />,
    extensionStorage: <ExtensionStorage {...{ changeSetting, extensions: !!storage.extensions }} />,
    externalExtensions: <ExternalExtensions {...{ changeSetting, enableExternalExtensions: !!storage.enableExternalExtensions }} />,
    resolution: <Resolution {...{ changeSetting, selectedResolution: navigator?.resolution, isAndroidProfile: os === 'android' }} />,
    deviceMemory: <DeviceMemory {...{ changeSetting, deviceMemory: navigator?.deviceMemory || 1 }} />,
    hardwareConcurrency: <HardwareConcurrency {...{ changeSetting, hardwareConcurrency: navigator?.hardwareConcurrency || 2 }} />,
    canvas: <Canvas {...{ mode: canvas.mode, changeSetting }} />,
    maxTouchPoints: <MaxTouchPoints {...{ changeSetting, maxTouchPoints: navigator?.maxTouchPoints || 1 }} />,
    audioContext: <AudioContext {...{ mode: audioContext.mode, changeSetting }} />,
    fonts: <Fonts {...{ changeSetting, os, profileFonts }} />,
    mediaDevices: <MediaDevices {...{ profileMediaDevices: mediaDevices, changeSetting }} />,
    webglImage: <WebglImage {...{ mode: webGL.mode || 'off' , changeSetting }} />,
    webglMetadata: <WebglMetadata {...{ webGLMetadata, changeSetting, webglParams }} />,
    startUrl: <StartUrl {...{ startUrl, changeSetting }} />,
    customDns: <CustomDns {...{ dns, changeSetting }} />,
    webRTCPublic: <WebRTCPublic {...{ webRTCMode: webRTC.enable, changeSetting }} />,
    bookmarks: <Bookmarks {...{ bookmarks, profileId, changeSetting }} />,
    extensions: <Extensions {...{ changeSetting, updateStateProfile, fullProfile }} />,
  };

  return isMultiProfilesSettings ? mixedParams : params;
};
