import React from 'react';

export interface IProfilesMenu {
  isBrowserUpdating: boolean;
  isProcessing: boolean;
  openFoldersModal: (profileIds: string[]) => void;
  openBookmarksManager: () => void;
  toggleModalTransfer: (state: boolean) => void;
  openExtensionsModal: () => void;
  cloneProfile: () => void;
  updateFingerprints: () => void;
  deleteOrLeaveMultipleProfiles: () => void;
  onRenameProfiles: () => void;
  redirectToSite: () => void;
  isStartDisabled: boolean;
  stopProfiles: () => void;
  startProfiles: () => void;
  limitForLaunch: number;
  setIsShowProxyManager: (state: boolean) => void;
  getLoader: () => JSX.Element|null;
  exportProfile: () => void;
  setAnchorElTooltip: (anchorEl: HTMLElement|null) => void;
  handleEditTagsClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export type PopconfirmProps = {
  title: string;
  onConfirm: () => void;
  cancelText: string;
  okText: string;
  visible?: boolean;
  disabled?: boolean;
}

export type MenuItemsPropsBase = {
  Icon: JSX.Element;
  text: string;
  onClick: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isVisible?: boolean;
  hasTooltip?: boolean;
  setAnchorElTooltip?: (anchorEl: HTMLElement|null) => void;
}

export type MenuItemsPropsWithConfirm = MenuItemsPropsBase & {
  hasPopconfirm: true;
  popconfirmProps: PopconfirmProps;
}

export type MenuItemsProps = MenuItemsPropsBase | MenuItemsPropsWithConfirm;

export const isMenuItemsPropsWithConfirm = (
  itemProps: MenuItemsProps,
): itemProps is MenuItemsPropsWithConfirm => {
  return (itemProps as MenuItemsPropsWithConfirm).hasPopconfirm;
};
