import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';
import { useAtomValue } from 'jotai';
import React, { FC, useMemo } from 'react';

import useDelayedMount from '../../../hooks/use-should-mount.hook';
import { tagsContext } from '../../../state';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { IBasicTableProfile } from '../../../state/profiles-table/basic-table-entities-subtypes';
import { closeTagManager, openTagManager, useTagManagerState } from '../../../state/tags/tag-manager-state.atom';
import { isFirefox } from '../../../utils/is-firefox';
import { IAddTag, ITagBase, TagField } from '../interfaces/tag.interface';
import AddTagPlaceholder from './add-tag-placeholder';
import EditModeTags from './edit-mode-tags';
import Tag from './tag';

const TagsWrapper = styled('section')<{
  isFirefox: boolean;
  newStyle: boolean;
  isOpen?: boolean;
  isPlaceholder?: boolean;
}>`
  position: relative;
  font-family: 'Roboto';
  font-style: normal;
  width: calc(100% - 1px);
  display: flex;
  align-items: flex-start;
  /*transition: box-shadow 0.25s ease-out;*/
  border-radius: 4px;

  ${(props): SerializedStyles | null =>
    props.isFirefox
      ? null
      : css`
          height: 100%;
        `}

  ${(props): SerializedStyles =>
    props.newStyle
      ? css`
          gap: 4px;
          padding: 16px 8px 12px;

          :hover {
            background-color: inherit;
            z-index: 104;
          }
        `
      : css`
          padding: 19px 8px 15px;
          min-height: 59px;
        `}

  ${(props): SerializedStyles | null =>
    props.newStyle && props.isPlaceholder
      ? css`
          padding: 14px 8px;
        `
      : null}

  ${(props): SerializedStyles | null =>
    props.newStyle && props.isOpen
      ? css`
          z-index: 150;

          :hover {
            z-index: 150;
          }
        `
      : null}
`;

const TagsListBlock = styled('div')<{ newStyle: boolean; isAddMode: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  gap: ${(props): string => (props.newStyle ? '4px' : '0')};

  ${(props): SerializedStyles | null =>
    props.newStyle
      ? css`
          z-index: ${props.isAddMode ? '103' : '100'};

          :hover {
            z-index: 104;
          }
        `
      : null}
`;

interface ITagsCell {
  profileAtom: IBasicTableProfile['atom'];
  rowIdx: IBasicTableProfile['idx'];
  addTag: (params: IAddTag) => Promise<void>;
  updateTag: (tag: ITagBase) => Promise<void>;
  removeProfileTag: (profileIds: string[], tagId: string) => Promise<void>;
  removeTag: (tagId: string, isInSuggest: boolean) => Promise<void>;
  onTagsPopupOpen: (profileTagsCount: number, totalTagsCount: number) => void;
  field: TagField;
}

const TagsCell: FC<ITagsCell> = (props) => {
  const { profileAtom, rowIdx, addTag, updateTag, removeProfileTag, removeTag, onTagsPopupOpen, field } = props;

  const {
    currentProfileId: tagManagerProfileId,
    currentField: tagManagerField,
    tagSelectorLocation: tagManagerSelectorLocation,
  } = useTagManagerState();

  const profile = useAtomValue(profileAtom);
  const { id: profileId, tags: profileRawTags } = profile;

  const { tags: allGlobalTags = [], setNewAddedTag, tagEditorVisible } = React.useContext(tagsContext);

  const globalTags = useMemo(() => allGlobalTags.filter((tag) => tag.field === field), [allGlobalTags]);
  const tags = useMemo(() => profileRawTags.filter((rawTag) => rawTag.field === field), [profileRawTags]);

  const profileIds = useMemo(() => [profileId], [profileId]);

  const selectorLocation = `${field}-cell-${rowIdx}`;
  const isAddMode =
    profileId === tagManagerProfileId && field === tagManagerField && selectorLocation === tagManagerSelectorLocation;

  const setIsAddMode = (): void => {
    const totalTagsCount = globalTags.length;
    const profileTagsCount = tags.length;
    onTagsPopupOpen(profileTagsCount, totalTagsCount);
    openTagManager({
      currentProfileId: profileId,
      tagSelectorLocation: selectorLocation,
      currentField: field,
    });
  };

  const handleHideDropdown = (event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      closeTagManager();
    }
  };

  React.useEffect(() => {
    if (isAddMode) {
      document.addEventListener('keydown', handleHideDropdown, true);
    } else {
      setNewAddedTag(null);
    }

    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true);
    };
  }, [tagEditorVisible, isAddMode]);

  const { shouldMount } = useDelayedMount(!!tags.length);
  if (!shouldMount) {
    return null;
  }

  return (
    <TagsWrapper
      newStyle={!!NEW_FEATURES.header}
      isFirefox={isFirefox()}
      isOpen={!!isAddMode}
      isPlaceholder={!tags.length}
      onClick={(): void => {
        if (!isAddMode) {
          setIsAddMode();
        }
      }}
    >
      <TagsListBlock newStyle={!!NEW_FEATURES.header} isAddMode={!!isAddMode}>
        {tags.map((tag, index) => (
          <Tag
            key={tag.id}
            tag={tag}
            updateTag={updateTag}
            isAddMode={false}
            removeTag={(): Promise<void> => removeProfileTag([profileId], tag.id)}
            isLastTag={index + 1 === tags.length}
          />
        ))}
        <AddTagPlaceholder setIsAddMode={setIsAddMode} showPlaceholder={!tags.length} field={field} />
        {isAddMode ? (
          <EditModeTags
            profileIds={profileIds}
            tags={tags}
            updateTag={updateTag}
            addTag={addTag}
            isAddMode={isAddMode}
            removeProfileTag={removeProfileTag}
            removeTag={removeTag}
            field={field}
          />
        ) : null}
      </TagsListBlock>
    </TagsWrapper>
  );
};

export default React.memo(TagsCell);
