import moment from 'moment';

import { ExtraActionInfo } from '../../../common/constants/analytics';
import { GEOPROXY_COUNT_KEY_PREFIX } from '../../../common/constants/local-storage';
import { LocalizationErrorMessages } from '../../../common/constants/localization-error-messages';
import { determineIsProxyCheckFailed, determineShouldChangeProtocol } from '../../../common/proxy/utils';
import { ProxyProtocol } from '../../../common/types/proxy';
import { TimezoneErrorPrefixes } from '../../../electron/interfaces/profile.status.manager.interfaces';
import { IArchivedProxy, IArchivedProxyInBrowser, IProxy, ProxyMode } from '../../interfaces';
import { NEW_FEATURES } from '../../state/feature-toggle/new-features';
import { mapAndSetProfilesList, updateProfilesArchivedProxy } from '../../state/profiles-list.atom';
import { getProxyRequest } from '../../state/proxies.context/api';
import { hideProxyCheckTooltip, showProxyCheckTooltip } from '../../state/proxy/proxy-check/proxy-check-tooltip.atom';
import { onProxyRestoreDone, onProxyRestoreStarted } from '../../state/proxy/proxy-check/proxy-statuses.atom';
import { IUngroupedProxy } from '../../state/proxy/proxy-groups/types';
import { setProfileSettingsProxyForm } from '../../state/proxy/proxy-in-profile-settings.atom';
import { getProxyList, removeProxiesFromList, updateProxyItem, upsertOneProxy } from '../../state/proxy/proxy-list.atom';
import { closeProxyManager, setProxyManagerCurrentProxy } from '../../state/proxy/proxy-manager-modal-status.atom';
import { MULTIPLE_PROXIES_ADD_ERRORS } from '../../state/proxy/proxy-operations/create-proxies.operations';
import { unlinkProfileProxy } from '../../state/proxy/proxy-operations/link-proxy.operations';
import { loadTrafficData } from '../../state/proxy/proxy-operations/load-geoproxy-traffic-data.operations';
import { loadProxyList } from '../../state/proxy/proxy-operations/load-proxies.operations';
import { toggleIsProxySelected, updateSelectedProxies } from '../../state/proxy/selected-proxies.atom';
import { getSharedProxies } from '../../state/proxy/shared-proxies.atom';
import { getCheckProxy } from '../../utils/check-proxy';
import countries from '../common/countries';
import { restoreProxiesRequest } from './api';
import { TRAFFIC_DATA_LOAD_TRIGGERS } from './constants';
import { PROXY_MODES_WITH_ACTIONS_ALLOWED } from './constants/settings';
import { determineIsArtificialProxyId } from './utils/proxy-id';

const GEOPROXY_CUSTOM_NAME_SEPARATOR = ' - ';

export const generateGeoProxyCustomName = (countryCode: string): string => {
  const storageKey = `${GEOPROXY_COUNT_KEY_PREFIX}${countryCode.toLowerCase()}`;
  const count = +(localStorage.getItem(storageKey) || '0');
  const countNew = count + 1;
  localStorage.setItem(storageKey, `${countNew}`);

  let countryName = countryCode.toUpperCase();
  const foundCountry = countries.find(({ code }) => code === countryCode);
  if (foundCountry) {
    countryName = foundCountry.name;
  }

  return `${countryName}${GEOPROXY_CUSTOM_NAME_SEPARATOR}${countNew}`;
};

const makeProxyTitleWithoutGroups = (proxy?: IProxy): string => {
  if (!proxy) {
    return '';
  }

  switch (true) {
    case proxy.mode === 'gologin':
      return `Free ${proxy.autoProxyRegion?.toLocaleUpperCase()}`;
    case proxy.mode === 'tor':
      return `Tor ${proxy.torProxyRegion?.toLocaleUpperCase()}`;
    case !!proxy.customName:
      return proxy.customName;
    case !!proxy.host:
      return proxy.host;
    case !!proxy.name:
      return proxy.name;
    default:
      return '';
  }
};

export const makeProxyTitle = (proxyEntity: IUngroupedProxy | IProxy): string => {
  if (!NEW_FEATURES.proxyGroupsV2) {
    return makeProxyTitleWithoutGroups(proxyEntity);
  }

  switch (true) {
    case proxyEntity.mode === 'gologin':
      return `Free / ${proxyEntity.autoProxyRegion?.toLocaleUpperCase()}`;
    case proxyEntity.mode === 'tor':
      return `Tor / ${proxyEntity.torProxyRegion?.toLocaleUpperCase()}`;
    case !!proxyEntity.customName:
      return proxyEntity.customName;
    case !!proxyEntity.host:
      return proxyEntity.host;
    case !!proxyEntity.name:
      return proxyEntity.name;
    default:
      return '';
  }
};

export type ProxyProtocolForUi = Exclude<ProxyProtocol, 'https'>;

export interface IStatusParams {
  status: boolean;
  country?: string;
  city?: string;
  error?: string;
  origin?: string;
  timezone?: string;
  languages?: string;
  mode?: ProxyProtocolForUi;
}

export const getProxyStatusParams = async (proxy: Omit<IProxy, 'createdAt'>, protocol?: ProxyMode): Promise<IStatusParams> => {
  const statusResponse = await getCheckProxy({ proxy });

  const statusParams: IStatusParams = {
    status: statusResponse?.status === 'success',
  };

  if (statusResponse?.country) {
    statusParams.country = `${statusResponse.country}`.toUpperCase();
  }

  if (statusResponse?.city) {
    statusParams.city = statusResponse.city;
  }

  if (statusResponse?.error) {
    statusParams.error = statusResponse.error;
   
    if (proxy.mode === 'geolocation') {
      loadTrafficData(TRAFFIC_DATA_LOAD_TRIGGERS.check);
    }
  }

  if (statusResponse?.origin) {
    statusParams.origin = statusResponse.origin;
  }

  if (statusResponse?.timezone) {
    statusParams.timezone = statusResponse.timezone;
  }

  if (statusResponse?.languages) {
    statusParams.languages = statusResponse.languages;
  }

  const checkableProxyMode = protocol || proxy.mode;
  const shouldChangeProtocol = determineShouldChangeProtocol(checkableProxyMode, statusResponse?.checkedProtocol);
  if (shouldChangeProtocol && statusResponse) {
    if (statusResponse.checkedProtocol === 'https') {
      statusResponse.checkedProtocol = 'http';
    }

    statusParams.mode = statusResponse.checkedProtocol;
  }

  return statusParams;
};

export const getProxyStatus = (proxy: IProxy): string | undefined => {
  const isTorOrFree = ['gologin', 'tor'].includes(proxy.mode);
  let status;
  if (isTorOrFree) {
    status = 'success';
  } else if (proxy.checkDate) {
    const daysDiff = (moment().unix() - moment(proxy.checkDate).unix()) / (60 * 60 * 24);

    if (!proxy.host || determineIsProxyCheckFailed(proxy)) {
      status = 'fail';
    } else if (daysDiff < 1 && proxy.status === true) {
      status = 'success';
    }
  }

  return status;
};

export const copyProxies = (proxies: IProxy[]): void => {
  const lines = proxies.map((proxy): string => {
    const { host, port, username, password, customName, changeIpUrl } = proxy;
    let textClipboard = '';

    if (host !== '' && port) {
      textClipboard = host + ':' + port;
    }

    if (username !== '') {
      textClipboard = username + '@' + host + ':' + port;
    }

    if (password !== '') {
      textClipboard = username + ':' + password + '@' + host + ':' + port;
    }

    if (changeIpUrl) {
      textClipboard = `${textClipboard}[${changeIpUrl}]`;
    }

    if (customName) {
      textClipboard = `${textClipboard}:${customName}`;
    }

    return textClipboard;
  });

  navigator.clipboard.writeText(lines.join('\n'));
};

type SelectProxiesWithShiftParams = {
  shiftKey: boolean;
  selectedProxies: string[];
  proxiesToSelectFrom: IProxy[];
  proxy: IProxy;
  lastSelectedProxy: string;
};

export const selectProxiesWithShift = ({
  shiftKey,
  selectedProxies,
  proxiesToSelectFrom,
  proxy,
  lastSelectedProxy,
}: SelectProxiesWithShiftParams): void => {
  if (!shiftKey) {
    updateSelectedProxies({ lastSelectedProxy: proxy.id });

    return toggleIsProxySelected(proxy.id);
  }

  const lastSelectedProxyIndex = proxiesToSelectFrom.findIndex((proxyEl) => proxyEl.id === lastSelectedProxy);
  const currentProxyIndex = proxiesToSelectFrom.findIndex((proxyEl) => proxyEl.id === proxy.id);
  const startIndex = Math.min(lastSelectedProxyIndex, currentProxyIndex);
  const endIndex = Math.max(lastSelectedProxyIndex, currentProxyIndex);

  const newCheckedProxies = proxiesToSelectFrom.slice(startIndex, endIndex + 1).map((proxyEl) => proxyEl.id);

  let newSelectedProxies;

  if (selectedProxies.includes(proxy.id)) {
    newSelectedProxies = selectedProxies.filter((val) => !newCheckedProxies.includes(val));
  } else {
    newSelectedProxies = [...new Set(newCheckedProxies.concat(selectedProxies))];
  }

  updateSelectedProxies({
    selectedProxies: newSelectedProxies,
    lastSelectedProxy: proxy.id,
  });
};

export const handleNoProxyClick = (event: React.SyntheticEvent, profileId: string | null = ''): void => {
  event.preventDefault();
  event.stopPropagation();

  if (!profileId) {
    return;
  }

  unlinkProfileProxy(profileId);
  closeProxyManager();
  hideProxyCheckTooltip();
};

export const getIsProxyEditable = (proxy: IProxy): boolean => {
  const sharedProxies = getSharedProxies();
  const isProxyShared = sharedProxies.some(({ id, profileId }) => id === proxy.id || profileId === proxy?.profileId);
  if (getIsProxyArchived(proxy)) {
    return true;
  }

  if (proxy?.id) {
    const isArtificialProxyId = determineIsArtificialProxyId(proxy.id);
    if (isArtificialProxyId) {
      return false;
    }

    const proxyList = getProxyList();
    const proxiesVisible = proxyList.filter((filteredProxy) => !filteredProxy.isInvisible);
    const isCurrentProxyNotInList = proxy?.id && !proxiesVisible.some(({ id }) => id === proxy.id);
    if (isCurrentProxyNotInList) {
      return false;
    }
  }

  return PROXY_MODES_WITH_ACTIONS_ALLOWED.includes(proxy.mode) && !(proxy.isInvisible || isProxyShared);
};

export const restoreProxy = async (archivedProxy: Partial<IArchivedProxy>): Promise<IProxy | null> => {
  const { id: archivedProxyId = '' } = archivedProxy;
  onProxyRestoreStarted([archivedProxyId]);
  let [restoredProxy] = await restoreProxiesRequest([archivedProxyId]);
  if (!restoredProxy) {
    restoredProxy = await getProxyRequest(archivedProxyId);
  }

  const { isRestoredByOwner = false } = restoredProxy || {};
  onProxyRestoreDone([archivedProxyId]);
  if (isRestoredByOwner) {
    upsertOneProxy(restoredProxy);
  }

  setProfileSettingsProxyForm(restoredProxy);
  mapAndSetProfilesList((profiles) =>
    profiles.map((profileInList) => {
      const profileArchivedProxyId = profileInList?.archivedProxy?.id;
      if (profileArchivedProxyId === restoredProxy.id) {
        delete profileInList.archivedProxy;

        return {
          ...profileInList,
          proxy: restoredProxy,
          proxyEnabled: true,
        };
      }

      return profileInList;
    }),
  );

  setProxyManagerCurrentProxy(restoredProxy);

  return restoredProxy;
};

export const getIsProxyArchived = (proxy?: Partial<IProxy | IArchivedProxy | IArchivedProxyInBrowser> | null): proxy is IArchivedProxy => {
  if (!proxy) {
    return false;
  }

  if (Object.hasOwn(proxy, 'isArchivedProxy') || Object.hasOwn(proxy, 'name')) {
    return true;
  }

  return false;
};

export const isCreateProxiesValidationError = (errorMessage: unknown): boolean => {
  if (!Array.isArray(errorMessage)) {
    return false;
  }

  const [validationError] = errorMessage;
  if (!validationError) {
    return false;
  }

  const { constraints = null } = validationError || {};
  if (!constraints) {
    return false;
  }

  return true;
};

export const getCreateProxyValidationError = (
  errorMessage: unknown,
  proxiesMultipleAddLimit?: number,
): { error: { message: string; isCustomError?: boolean; count?: number } } => {
  const createProxiesError = {
    error: {
      message: String(errorMessage) || LocalizationErrorMessages.SomethingWentWrongAgainLater,
      isCustomError: true,
    },
  };

  if (!Array.isArray(errorMessage)) {
    return createProxiesError;
  }

  const [validationError] = errorMessage;
  if (!validationError) {
    return createProxiesError;
  }

  const { constraints = null } = validationError || {};
  if (!constraints) {
    return createProxiesError;
  }

  const { arrayMaxSize = '' } = constraints || {};
  if (arrayMaxSize) {
    return {
      error: {
        message: MULTIPLE_PROXIES_ADD_ERRORS.limitReachedError,
        count: proxiesMultipleAddLimit || 30,
      },
    };
  }

  const constraintsFirstValue = getFirstConstraintValue(constraints);

  if (constraintsFirstValue) {
    return {
      error: {
        message: constraintsFirstValue,
        isCustomError: true,
      },
    };
  }

  return createProxiesError;
};

const getFirstConstraintValue = (constraints: Record<string, unknown>): string => {
  const constraintKeys = Object.keys(constraints);
  if (constraintKeys.length > 0) {
    const [firstKey] = constraintKeys;
    if (typeof constraints[firstKey] === 'string') {
      return constraints[firstKey];
    }
  }

  return '';
};

export const handleActiveProxiesAlreadyArchived = async (
  archivedProxies: IArchivedProxyInBrowser[],
  shouldLoadAllProxies?: boolean,
): Promise<void> => {
  if (!archivedProxies?.length) {
    return;
  }

  updateProfilesArchivedProxy(archivedProxies);
  archivedProxies.forEach((archivedProxy) => {
    removeProxiesFromList([archivedProxy?.id]);
  });

  shouldLoadAllProxies && loadProxyList();
};

export const makeArchivedProxyFromProxy = (proxy: IProxy): IArchivedProxyInBrowser => {
  const { id, country = '', customName = '', host = '' } = proxy || {};
  const name = customName || host || '';

  return { id, country, name };
};

type SyncProxyTooltipProps = {
  profileId: string;
  statusMessage: any;
  proxyId: string;
};

export const syncProxyTooltipWithProfileStatus = ({ profileId, statusMessage, proxyId }: SyncProxyTooltipProps): void => {
  let errorMessage = 'profilesTable.error.proxyNonfunctional';
  if (typeof statusMessage === 'string') {
    errorMessage = statusMessage;
  } else if (statusMessage?.prefix === TimezoneErrorPrefixes.proxy) {
    ({ errorMessage } = statusMessage);
  }

  updateProxyItem({
    id: proxyId,
    checkDate: moment().toDate(),
    status: false,
    error: errorMessage,
  });

  const proxyDataForCheckTooltip: IProxy = {
    id: proxyId,
    host: '',
    port: '',
    profilesCount: 0,
    mode: 'none',
  };

  showProxyCheckTooltip({
    profileIds: [profileId],
    proxies: [proxyDataForCheckTooltip],
    view: 'selector-profile-table',
    timeout: 2 * 1000,
  });
};

export const generateProxyAnalyticsData = (proxy: IProxy): Pick<ExtraActionInfo, 'proxyId' | 'proxyMode' | 'proxyCountry' | 'actionInfo'> =>
  !proxy ? {} : ({
    proxyId: proxy.id,
    proxyMode: proxy.mode,
    proxyCountry: proxy.country?.toUpperCase(),
    actionInfo: proxy.customName,
  });
