import { css } from '@emotion/core';
import { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled/macro';

import { textFont } from '../style-templates';

export const SelectWrapper = styled('div')<{ isDisableDarkTheme?: boolean }>`
  ${({ isDisableDarkTheme }): SerializedStyles|null => isDisableDarkTheme ? css`
    --2B2B31-profile-new-settings-select: var(--2B2B31-new-pricing-select);
    --FFFFFF-profile-new-settings-select: var(--FFFFFF-new-pricing-select);
    --EAEAEB-profile-new-settings-select: var(--EAEAEB-new-pricing-select);
    --81818A-profile-new-settings-select: var(--81818A-new-pricing-select);
    --D2D2D5-profile-new-settings-select: var(--D2D2D5-new-pricing-select);
    --F2F2F3-profile-new-settings-select: var(--F2F2F3-new-pricing-select);
  ` : null}
`;

export const Select = styled('div')<{ disabled?: boolean }>`
  ${textFont};
  color: var(--2B2B31-profile-new-settings-select);
  z-index: 300;
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  pointer-events: ${(props): string => props.disabled ? 'none' : 'all'};
`;

export const SearchInput = styled('input')`
  ${textFont};
  width: 100%;
  border: none;
  outline: none;
  padding: 0;
  color: var(--2B2B31-profile-new-settings-select);
  z-index: 300;
  max-width: 250px;
  background-color: inherit;

  ::placeholder {
    color: var(--2B2B31-profile-new-settings-select);
  }

  :focus {
    ::placeholder {
      color: var(--2B2B31-profile-new-settings-select);
    }
  }
`;

export const HiddenContainer = styled('div')`
  position: absolute;
  visibility: hidden;
  white-space: pre;
  padding: 0;
  margin: 0;
`;

export const Options = styled('div')<{ optionsVisible?: boolean }>`
  padding: 4px 0;
  outline: var(--gologin-popover-outline);
  border-radius: 4px;

  z-index: 300;
  position: absolute;
  top: 100%;
  left: 0;

  display: ${(props): string => props.optionsVisible ? 'flex' : 'none'};
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;

  background: var(--gologin-popover-background);
  box-shadow: var(--box-shadow-popup);
  overflow: auto;
  overflow-x: hidden;

  cursor: pointer;

  .infinite-scroll-component__outerdiv {
    width: 100%;
  }
`;

export const Option = styled('div')<{ isSelected?: boolean }>`
  width: auto;
  padding: 6px 16px;
  border-radius: 4px;
  margin: 0 4px;

  z-index: 300;

  display: flex;
  flex: none;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  gap: 4px;

  ${textFont};

  background-color: ${(props): string => props.isSelected ? 'var(--gologin-popover-item-selected-background)' : 'inherit'};

  &:hover {
    background: var(--gologin-popover-item-hovered-background);
  }

  b {
    font-weight: 400;
    color: var(--2B2B31-profile-new-settings-select);
  }
`;

export const Mask = styled('div')`
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 250;
`;

export const SubtitleContainer = styled('div')`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: var(--81818A-profile-new-settings-select);
  padding: 6px 0;
  margin: 0 20px;
  width: 100%;
`;

export const Subtitle = styled('div')`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: var(--81818A-profile-new-settings-select);
  display: flex;
  flex-direction: row;
  padding: 6px 16px;
`;

export const Divider = styled('div')`
  background: transparent;
  width: 100%;
  margin: 4px 0;
  border-bottom: 0.5px solid var(--D2D2D5-profile-new-settings-select);
`;

export const SelectArrowContainer = styled('div')<{ isOpen?: boolean }>`
  margin-left: 8px;
  transform: rotate(90deg);
  opacity: ${(props): number => props.isOpen ? 1 : 0};
`;

export const SelectButtonContainer = styled('div')<{ width?: string }>`
  ${textFont};
  color: var(--2B2B31-profile-new-settings-select);
  width: ${(props): string => props.width || 'auto'};
  display: flex;
  align-items: center;
  background-color: inherit;
  text-transform: none;
  cursor: pointer;

  :hover {
    ${SelectArrowContainer} {
      opacity: 1;
    }
  }
`;

export const Hint = styled('span')`
  ${textFont};
  letter-spacing: 0.25px;
  color: var(--81818A-profile-new-settings-select);
`;

export const Ellipsis = styled('span')<{ disabled?: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props): string => props.disabled ? 'var(--B5B5BA-profile-new-settings-input-start-url)' : 'inherit'};
`;

export const ContainerFoundItem = styled(Ellipsis)`
  color: var(--81818A-profile-new-settings-select);

  b {
    font-weight: 400;
    color: var(--2B2B31-profile-new-settings-select);
  }
`;
