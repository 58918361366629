import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonRun, ButtonStop, DisabledButtonRun, DrawerButtonRun, DrawerButtonStop } from './styles';
import ViewProfileButton from './view-button';
import { E_ANALYTICS_ACTIONS } from '../../../../common/constants/analytics';
import { IAutomation, IProfile } from '../../../interfaces';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { useProfileRunStatus } from '../../../state/profile-run-statuses.atom';
import { getProfilesListProfileById } from '../../../state/profiles-list.atom';
import { getProxyListProxyById } from '../../../state/proxy/proxy-list.atom';
import { IconSpinner } from '../../../ui/gologin-header/icons';
import TooltipCustom from '../../../ui/tooltip-custom';
import { sendActionAnalytics } from '../../common/api';
import { EMPTY_PROXY } from '../../proxy/constants';
import { generateProxyAnalyticsData } from '../../proxy/proxy-helpers';
import { stopProfileWeb, launchProfileWeb, stopProfileOrbita } from '../actions';
import { ILaunchProfileOrbita } from '../components/interfaces/launch-profile-orbita.interface';

const isElectron = !!window.require;

type IProfileRunOnly = Pick<IProfile, 'id'|'isWeb'|'navigator'|'canBeRunning'>;

interface IRunProfileButton {
  profile: IProfileRunOnly;
  automation?: IAutomation;
  browserUpdating?: boolean;
  versionLoadingNow?: string;
  isInDrawer?: boolean;
  launchProfileOrbita: (opts: ILaunchProfileOrbita) => void;
}

const RunProfileButton: FC<IRunProfileButton> = (props): JSX.Element|null => {
  const {
    profile,
    automation,
    browserUpdating,
    versionLoadingNow,
    isInDrawer,
    launchProfileOrbita,
  } = props;

  const isOldDrawer = isInDrawer && !NEW_FEATURES.drawer;

  const [anchorElTooltip, setAnchorElTooltip] = useState<HTMLElement|null>(null);

  const { status, isWeb, remoteOrbitaUrl } = useProfileRunStatus(profile.id);

  const { t: translation } = useTranslation();

  const sendRunAnalytics = (profilesListProfile: IProfile | null): void => {
    const { id = '', name = '', proxy = EMPTY_PROXY, isAutoGenerated = false } = profilesListProfile || {};
    const proxyListProxy = getProxyListProxyById(proxy?.id);
    const proxyAnalyticsData = generateProxyAnalyticsData(proxyListProxy || proxy);
    sendActionAnalytics(E_ANALYTICS_ACTIONS.clickedRunProfile, {
      profile: id,
      actionInfo: name,
      mode: isAutoGenerated ? 'default profile' : '',
      ...proxyAnalyticsData,
    });
  };

  const onRunClick = (): void => {
    const profilesListProfile = getProfilesListProfileById(profile?.id);
    sendRunAnalytics(profilesListProfile);

    if (isElectron) {
      launchProfileOrbita({ profile, automation });

      return;
    }

    launchProfileWeb(profile);
  };

  if (automation && status !== 'profileStatuses.ready') {
    return null;
  }

  const showTooltip = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    setAnchorElTooltip(event.currentTarget);
  };

  const hideTooltip = (): void => setAnchorElTooltip(null);

  if (status === 'profileStatuses.running') {
    if (profile.isWeb || isWeb) {
      return (
        <>
          <ViewProfileButton profileId={profile.id} remoteOrbitaUrl={remoteOrbitaUrl} />
          {isOldDrawer ? (
            <DrawerButtonStop
              style={{ marginLeft: 10 }}
              onClick={(): void => {
                stopProfileWeb(profile.id);
                sendActionAnalytics('clicked stop profile');
              }}
            >
              {translation('quickSettings.stopBtnTxt')}
            </DrawerButtonStop>
          ) : (
            <ButtonStop
              newStyle={!!NEW_FEATURES.header}
              onClick={(): void => {
                stopProfileWeb(profile.id);
                sendActionAnalytics('clicked stop profile');
              }}
            >
              {translation('base.stop')}
            </ButtonStop>
          )}
        </>
      );
    }

    return (
      <>
        {isElectron ? (
          <ViewProfileButton profileId={profile.id} remoteOrbitaUrl={remoteOrbitaUrl} />
        ) : null}
        {isOldDrawer ? (
          <DrawerButtonStop
            style={{ marginLeft: 10 }}
            onClick={(): void => {
              stopProfileOrbita(profile.id);
              sendActionAnalytics('clicked stop profile');
            }}
          >
            {translation('quickSettings.stopBtnTxt')}
          </DrawerButtonStop>
        ) : (
          <ButtonStop
            newStyle={!!NEW_FEATURES.header}
            onClick={(): void => {
              stopProfileOrbita(profile.id);
              sendActionAnalytics('clicked stop profile');
            }}
          >
            {translation('base.stop')}
          </ButtonStop>
        )}
      </>
    );
  }

  const disabled = !profile.canBeRunning;
  const chromeVersionMatch = profile?.navigator?.userAgent && profile.navigator.userAgent.match(/[\s\S\w]+Chrome\/(\d+)/) || [];
  if (!chromeVersionMatch) {
    return null;
  }

  const [_, version = ''] = chromeVersionMatch;
  const isBrowserUpdating =
    browserUpdating
    && (Number(version) === Number(versionLoadingNow) || versionLoadingNow === '');

  const loading = !['profileStatuses.ready', 'profileStatuses.error', 'profileStatuses.running'].includes(status || '');
  if (loading && !isOldDrawer) {
    return (
      <ButtonRun
        newStyle={!!NEW_FEATURES.header}
        isLoading={true}
        style={{ background: 'transparent' }}
      >
        <IconSpinner />
      </ButtonRun>
    );
  }

  if (loading && isOldDrawer) {
    return (
      <DrawerButtonRun
        size='small'
        loading={true}
      >
        {translation('quickSettings.syncingTxt')}
      </DrawerButtonRun>
    );
  }

  if (disabled || isBrowserUpdating) {
    const tooltipText = isBrowserUpdating ?
      translation('notifications.error.orbitaIsDownloading') :
      translation('notifications.error.runByAnotherUser');

    return (
      <>
        <DisabledButtonRun
          newStyle={!!NEW_FEATURES.header}
          onMouseEnter={showTooltip}
          onMouseLeave={hideTooltip}
        >
          {isOldDrawer ? translation('quickSettings.runBtnTxt') : translation('base.run')}
        </DisabledButtonRun>
        <TooltipCustom anchorEl={anchorElTooltip} value={tooltipText} />
      </>
    );
  }

  if (isOldDrawer) {
    return (
      <DrawerButtonRun
        size='small'
        onClick={onRunClick}
        loading={loading}
      >
        {translation('quickSettings.runBtnTxt')}
      </DrawerButtonRun>
    );
  }

  return (
    <ButtonRun
      newStyle={!!NEW_FEATURES.header}
      isLoading={loading}
      onClick={onRunClick}
    >
      {translation('base.run')}
    </ButtonRun>
  );
};

export default RunProfileButton;
