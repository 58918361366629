import { message } from 'antd';
import React from 'react';
import { Trans } from 'react-i18next';

import { filterProfilesByPermission } from '../../../../state/profiles-list.atom';
import { openProfilesTableModal, TRANSFER_PROFILE_MODAL_NAME } from '../../../../state/profiles-table-modal.atom';
import { getProfilesTableSelectedIds } from '../../../../state/profiles-table-selected-ids.atom';

export const openTransferModal = (): void => {
  const selectedProfileIds = getProfilesTableSelectedIds();
  const canTransferProfiles = filterProfilesByPermission(selectedProfileIds, 'transferProfile');

  if (!canTransferProfiles) {
    message.error(<Trans i18nKey='notifications.error.permissionWorkspace' />);

    return;
  }

  openProfilesTableModal(TRANSFER_PROFILE_MODAL_NAME, selectedProfileIds);
};
