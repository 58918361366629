import styled from '@emotion/styled/macro';

import { GologinPopoverItemRow } from '../../../../../../ui/gologin-popover/gologin-popover-item-row';
import { purchaseTrafficButtonActiveStyles } from '../../purchase-traffic-button/styles';
import css from '@emotion/css';

const DEFAULT_GAP: `${number}px` = '8px';

export const ADD_BUTTON_ARROW_COLOR = 'var(--98989F-proxy-manager-icon-buttons)';
export const ADD_BUTTON_ARROW_ACTIVE_COLOR = 'var(--proxy-group-header-arrow-active-color)';
export const ADD_BUTTON_ARROW_ACTIVE_BACKGROUND_COLOR = 'var(--proxy-group-header-arrow-hovered-background)';

const TITLE_TO_POPOVER_LEFT_BORDER_DISTANCE = 40;

export const PROXY_GROUP_ADD_BUTTON_POPOVER_WIDTH = 260;

export const IconArrowDownWrapper = styled.div<{ isHighlighted: boolean }>`
  cursor: pointer;

  ${(props) => props.isHighlighted ? css`
    & > div {
      background-color: ${ADD_BUTTON_ARROW_ACTIVE_BACKGROUND_COLOR};
    }
  ` : null}

  :hover {
    & > div {
      background-color: ${ADD_BUTTON_ARROW_ACTIVE_BACKGROUND_COLOR};
    }

    svg path {
      stroke: ${ADD_BUTTON_ARROW_ACTIVE_COLOR};
    }
  }
`;

export const AddButtonPopoverRow = styled(GologinPopoverItemRow)`
  :hover {
    ${purchaseTrafficButtonActiveStyles}
  }
`;

export const AddButtonPopoverRowContent = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  /* gap */
  & > * {
    margin-left: ${DEFAULT_GAP};
  }
`;

export const AddButtonPopoverRowTitle = styled.span`
  max-width: ${(PROXY_GROUP_ADD_BUTTON_POPOVER_WIDTH - TITLE_TO_POPOVER_LEFT_BORDER_DISTANCE) / 2}px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AddButtonPopoverRowRightControls = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
