/* eslint-disable max-lines */
/* eslint-disable max-len */
import { message } from 'antd';
import moment from 'moment';
import React, { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteProps, useLocation, useRouteMatch } from 'react-router-dom';

import { RESOLUTIONS } from './all-resolutions';
import { requestUser, requestUserWorkspaces } from './api';
import { makeAutoLogin } from './auto-login';
import handlePageLoadError from './handle-page-load-error';
import LoadingPage from './loading-page';
import switchWorkspace, { IUserCtxSwitchFields } from './switch-workspace';
import WrapperGlobal from './wrapper-global';
import { TWO_FA_TOKEN_KEY_NAME } from '../../../common/constants/constants';
import { PRICING_PAGE } from '../../../common/constants/routes';
import { wait } from '../../../common/utils';
import { useQuery } from '../../hooks';
import { updateClientId } from '../../initGA';
import { IFolder } from '../../interfaces';
import { ThemeType } from '../../interfaces/theme.type';
import { IWorkspace } from '../../interfaces/workspaces';
import { history } from '../../services';
import { MEMBER_TOKEN, ACCESS_TOKEN } from '../../services/http/config';
import { retrieveToken } from '../../services/http/storage';
import { checkConditionUsingSentry } from '../../services/init-sentry';
import {
  ISharedFolder,
  IUser,
  IWorkspaceCtx,
  TEMPLATE_DEFAULT_VALUE,
  setDiscountsList,
  setPlansAddonPricingConfig,
  setPlansList,
  setPricingConfig,
  setPromoDiscount,
  templatesContext,
  userContext,
  workspaceContext,
} from '../../state';
import { NEW_FEATURES, toggleNewUserFeature } from '../../state/feature-toggle/new-features';
import { setWorkspaceLimits, setIsWorkspaceLimitsAvaliable } from '../../state/limits/workspace-limits.atom';
import { getLocalUserDevice } from '../../state/local-user-device.atom';
import { setDefaultChosenLimits } from '../../state/pricing/pricing-page.atom';
import { setUpgradeDiscount } from '../../state/pricing/upgrade-discount.atom';
import { updatePricingData } from '../../state/pricing-data.atom';
import { resetProfilesTable } from '../../state/profiles-list.atom';
import { getProfilesTableProxyIdFilter, setProfilesTableProxyIdFilter } from '../../state/profiles-proxy-filter.atom';
import { resetProxiesTable } from '../../state/proxy/proxy-table/proxies-table-list.atom';
import { setShouldOpenQuickPricingAtLaunch } from '../../state/quick-pricing.atom';
import { switchTheme } from '../../state/theme.atom';
import { createAppStartTransactionSpan } from '../../utils/app-start.transaction.utils';
import { openWebsitePricing } from '../../utils/open-site';
import { getUserScreenInfo, getUserScreenResolution } from '../../utils/user-hardware.utils';
import { UserSessionService } from '../../utils/user-session-init';
import { requestPricingData } from '../pricing/api';
import { PLAN_IDS } from '../pricing/constants';

let ipcRenderer: Electron.IpcRenderer | null = null;
const isElectron = !!window.require;

if (isElectron) {
  ({ ipcRenderer } = window.require('electron'));
}

const userAuthenticated = (): boolean => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN);

  return !!accessToken;
};

interface IGetUserInfo {
  id: string;
  userEmail: string;
  defaultWorkspace: string;
  sharedFolders: ISharedFolder[];
}

declare interface IPrivateRoute extends RouteProps {
  workspaceCtx?: IWorkspaceCtx;
}

const PR: FC<IPrivateRoute> = (props) => {
  const userCtx = useContext(userContext);
  const { _id, updateUser, gologinMetadata: contextGologinMetadata = {} } = userCtx;
  const { updateAvailableTemplates } = useContext(templatesContext);
  const workspaceCtx = useContext(workspaceContext);
  const {
    updateWorkspace,
    updateAvailableWorkspaces,
    id: currentWorkspaceId,
    isLoaded: workspaceIsLoaded,
    isUnpaid: workspaceIsUnpaid,
    paymentIsTrial: workspaceIsTrial,
  } = workspaceCtx;

  const location = useLocation();
  const query = useQuery();

  const { i18n } = useTranslation();

  const showConfirmationEmailMessage = (): void => {
    const isEmailConfirmed = query.get('email_confirmed');
    if (!isEmailConfirmed) {
      return;
    }

    query.delete('email_confirmed');
    const searchQuery = query.toString();

    if (searchQuery) {
      history.push('/personalArea/overview?' + searchQuery);
    } else {
      history.replace('/personalArea/overview');
    }

    message.success('Your email confirmed');
  };

  useEffect(() => {
    if (!workspaceIsLoaded) {
      return;
    }

    createAppStartTransactionSpan('from-end-loading-to-load-profiles-table');
  }, [workspaceIsLoaded]);

  useEffect(() => {
    if (isElectron) {
      ipcRenderer && ipcRenderer.invoke('check-is-update-ready');

      if (location.pathname === '/profileList') {
        ipcRenderer && ipcRenderer.invoke('check-app-update');
      }

      ipcRenderer && ipcRenderer.invoke('check-competitors-metadata', contextGologinMetadata);
    }
  }, [location]);

  useEffect(() => {
    requestPricingData({ workspaceId: currentWorkspaceId }).then((pricingData) => {
      updatePricingData(pricingData);
      const { discounts, plans, promoDiscount, pricingConfig, upgradeDiscount, limits, plansAddonPricingConfig } = pricingData;
      setPlansList(plans);
      setDiscountsList(discounts);
      setPromoDiscount(promoDiscount);
      setPricingConfig(pricingConfig);
      setUpgradeDiscount(upgradeDiscount);

      if (limits && plansAddonPricingConfig) {
        setWorkspaceLimits(limits);
        setIsWorkspaceLimitsAvaliable(true);
        setPlansAddonPricingConfig(plansAddonPricingConfig);
        setDefaultChosenLimits();
      }

      checkConditionUsingSentry(pricingData.userData, pricingData.workspace);
    });
  }, [_id, currentWorkspaceId]);

  const routeUpdateObj = useRouteMatch('/update/:profileId');
  const routeProfilesObj = useRouteMatch('/profileList*');
  const routeProxyObj = useRouteMatch('/proxy*');
  const isUpdatePage = routeUpdateObj?.isExact;
  const isProfilesPage = routeProfilesObj?.isExact;
  const isProxyPage = routeProxyObj?.isExact;

  useEffect(() => {
    if (!isProfilesPage) {
      return;
    }

    const proxyIdFilter = getProfilesTableProxyIdFilter();
    resetProfilesTable();
    if (proxyIdFilter) {
      setProfilesTableProxyIdFilter(proxyIdFilter);
    }
  }, [isUpdatePage, isProfilesPage]);

  useEffect(() => {
    if (isProxyPage) {
      return;
    }

    resetProxiesTable();
  }, [isProxyPage]);

  useEffect(() => {
    showConfirmationEmailMessage();
  }, []);

  useEffect(() => {
    async function getUserInfo(): Promise<IGetUserInfo|null> {
      const userRequestData = await requestUser()
        .catch((err) => handlePageLoadError('user', err, workspaceCtx));

      if (!userRequestData) {
        return null;
      }

      ipcRenderer && ipcRenderer.invoke('set-proxy-checker-settings', {
        isProxyServerCheckEnabled: userRequestData.isProxyServerCheckEnabled,
        proxyCheckerIgnoreCodes: userRequestData.proxyCheckerIgnoreCodes,
      });

      const {
        email,
        createdAt,
        _id: userId,
        plan = {},
        card = {},
        subscription = {},
        payment = {},
        profiles,
        profilesCountWithShares = 0,
        hasSuccessPayment,
        paypalSubsCount = 0,
        paypalSubscriptionId = '',
        planExpireDate = '',
        firstProfileStarted = false,
        targetPageShown = false,
        firstPlanSelected = false,
        isEmailConfirmed = false,
        isQuickSettingsEnabled = false,
        defaultWorkspace = '',
        sharedFolders = [],
        isTwoFaEnabled = false,
        realPayments = 0,
        templates = [TEMPLATE_DEFAULT_VALUE],
        profileRuns = 0,
        lastAppVersion = '',
        lastBrowserVersion = '',
        isCorrectlyRegistered = false,
        folders = [],
        newFeatures = {},
        isLastPaymentTrialEnded = false,
        invitedByLink = '',
        invitedByUser = '',
        migrationData = {},
        registrationLead = {},
        googleClientId,
        gologinMetadata = {},
      } = userRequestData;

      toggleNewUserFeature(newFeatures);
      updateClientId(googleClientId);

      if (isQuickSettingsEnabled) {
        localStorage.setItem('@gologin:quickSettingsEnabled', 'true');
      }

      const paymentExpiresIn = new Date(subscription?.payment?.expiresIn || payment.expiresIn);
      const isTrial = !!(subscription?.payment?.isTrial || payment.isTrial);
      const hasTrial = isTrial && (paymentExpiresIn > new Date());
      const lastPaymentDate = payment?.createdAt;

      if (!plan?._id) {
        plan._id = '';
      }

      const UserSessionServiceInst = UserSessionService.getInstance();
      UserSessionServiceInst.setUserId(userId);
      UserSessionServiceInst.setUserInfo = {
        _id: userId,
        realPayments,
        lastPaymentDate,
        isCorrectlyRegistered,
        lastAppVersion,
        hasTrial,
        plan,
        email,
        registrationLead,
      };

      UserSessionServiceInst.startRecording();

      const rawCachedFolders = localStorage.getItem('folders-order');
      const cachedFolders: IFolder[] = rawCachedFolders ? JSON.parse(rawCachedFolders) : [];

      const orderedFolders = folders
        .map((folder: IFolder, index: number) => {
          const cachedFolder = cachedFolders.find(ff => ff.name === folder.name);
          if (cachedFolder) {
            return { ...folder, order: cachedFolder.order };
          }

          return { ...folder, order: cachedFolders.length + index };
        })
        .sort((left: any, right: any) => left.order - right.order);

      const lightPage = location.pathname === '/download';
      if (!NEW_FEATURES.header || lightPage) {
        switchTheme('light');
      }

      if (NEW_FEATURES.header) {
        localStorage.setItem('folders-order', JSON.stringify(orderedFolders));

        let currentTheme = localStorage.getItem('theme') || 'system';
        if (ipcRenderer) {
          const appConfig = await ipcRenderer.invoke('get-app-config');
          currentTheme = appConfig.theme || currentTheme;
        }

        switchTheme(currentTheme as ThemeType);
      }

      let selectedFolder = localStorage.getItem('SelectedFolder') || '';
      const existedFolder = orderedFolders.find((folder: IFolder) => folder?.name === selectedFolder);

      if (!existedFolder) {
        selectedFolder = '';
        localStorage.setItem('SelectedFolder', '');
      }

      const userData: Partial<IUser> = {
        _id: userId,
        email,
        createdAt,
        plan,
        firstPlanSelected,
        profiles,
        profilesCountWithShares,
        hasTrial,
        isEmailConfirmed,
        payment,
        card,
        trialDays: isTrial && (paymentExpiresIn > new Date())
          ? moment(subscription?.payment?.expiresIn || payment.expiresIn).diff(moment(), 'days') + 1
          : 0,
        hasSuccessPayment,
        paypalSubsCount,
        paypalSubscriptionId,
        planExpireDate: payment?.additionalParams?.expiresIn || planExpireDate,
        targetPageShown,
        isQuickSettingsEnabled,
        realPayments,
        defaultWorkspace,
        sharedFolders,
        is2faEnabled: isTwoFaEnabled,
        profileRuns,
        lastAppVersion,
        lastBrowserVersion,
        isCorrectlyRegistered,
        lastPaymentDate,
        newFeatures,
        selectedFolder,
        isLastPaymentTrialEnded,
        invitedByLink,
        invitedByUser,
        migrationData,
        gologinMetadata,
      };

      setShouldOpenQuickPricingAtLaunch(!firstProfileStarted);

      updateUser(userData);

      // private-router срабатывает раньше, чем refreshLocalUserDevice на странице профилей, поэтому при запуске апп
      // getLocalUserDevice не вернет разрешение юзера, приходится его получать самим.
      let userScreenResolution: typeof RESOLUTIONS[number] | '' = '';
      if (isProfilesPage) {
        const localUserDeviceInfo = getLocalUserDevice();
        let { screen } = localUserDeviceInfo;
        if (!screen.width) {
          screen = await getUserScreenInfo();
        }

        userScreenResolution = getUserScreenResolution(screen);
      }

      updateAvailableTemplates(templates, userScreenResolution);

      const freePlan = hasSuccessPayment || plan._id === PLAN_IDS.ForeverFree;
      const need3DSPayment = !(hasTrial || freePlan);
      const pathName = location?.pathname || '';

      if (!targetPageShown) {
        history.replace('/quiz');
      }

      const shouldOpenPricing = !NEW_FEATURES.workspaces && need3DSPayment && !(pathName.includes('personalArea') || pathName.startsWith(PRICING_PAGE));
      if (shouldOpenPricing) {
        openWebsitePricing({ isShowPaymentMethods: !isElectron });
      }

      return {
        id: userId,
        userEmail: email,
        defaultWorkspace,
        sharedFolders,
      };
    }

    const fetchWorkspaceIdToLoad = async (): Promise<string | null> => {
      const workspacesRequestData = await requestUserWorkspaces()
        .catch((err) => handlePageLoadError('workspaces', err, workspaceCtx));

      if (!workspacesRequestData) {
        return null;
      }

      const { workspaces } = workspacesRequestData;
      updateAvailableWorkspaces(workspaces);

      if (!workspaces.length) {
        return null;
      }

      if (currentWorkspaceId) {
        const currentWorkspace = workspaces.find(workspace => workspace.id === currentWorkspaceId);
        const { isUnpaid, paymentIsTrial, planExpiresAt, planId } = currentWorkspace || {};
        if (!planExpiresAt) {
          return null;
        }

        const UserSessionServiceInst = UserSessionService.getInstance();
        UserSessionServiceInst.setWorkspaceInfo({
          workspaceIsUnpaid: isUnpaid,
          workspaceIsTrial: paymentIsTrial,
          workspacePlanId: planId,
        });

        updateWorkspace({ isUnpaid, paymentIsTrial, planExpiresAt, planId });
        if (currentWorkspace) {
          return null;
        }
      }

      const localWorkspace = localStorage.getItem('workspace');
      let localWorkspaceId: string | null = null;
      if (localWorkspace) {
        try {
          localWorkspaceId = JSON.parse(localWorkspace).id;
          // eslint-disable-next-line no-empty
        } catch (err) { }
      }

      let workspaceIdToLoad = workspaces[0].id;
      if (localWorkspaceId) {
        const foundLocalId = workspaces.find(workspace => workspace.id === localWorkspaceId);
        if (foundLocalId) {
          workspaceIdToLoad = localWorkspaceId;
        }
      }

      return workspaceIdToLoad;
    };

    const openWorkspace = async (workspaceIdToLoad: string, userCtxSwitchFields: IUserCtxSwitchFields): Promise<IWorkspace | null> => switchWorkspace(workspaceIdToLoad, workspaceCtx, userCtxSwitchFields).finally(async () => {
      // after we tried to load the workspace,
      // if we failed to be workspace member last time,
      // we clear this localStorage item after a small pause to not get stuck in loop
      // and we have to use localStorage in the first place because error arrives from http client error handler
      const notMemberStorageItem = localStorage.getItem('open-workspace-failed-not-member');
      if (notMemberStorageItem) {
        let shouldClearStorage = false;
        try {
          shouldClearStorage = JSON.parse(notMemberStorageItem);
        } catch (err) {
          // do nothing
        }

        if (!shouldClearStorage) {
          return;
        }
      }

      wait(500).then(() => localStorage.removeItem('open-workspace-failed-not-member'));
    });

    makeAutoLogin({ query, i18n })
      .then(async () => {
        if (userAuthenticated()) {
          const user = await getUserInfo();
          if (!user) {
            return;
          }

          const workspaceIdToLoad = await fetchWorkspaceIdToLoad();
          const userFields: IUserCtxSwitchFields = {
            defaultWorkspace: user.defaultWorkspace,
            sharedFolders: user.sharedFolders,
            updateFolders: userCtx.updateFolders,
          };

          if (workspaceIdToLoad) {
            await openWorkspace(workspaceIdToLoad, userFields);
          }

          updateWorkspace({ userId: user.id });

          const isAccountSelected = sessionStorage.getItem('selected_account');
          ipcRenderer && ipcRenderer.invoke('set-token', {
            token: isAccountSelected ? sessionStorage.getItem(MEMBER_TOKEN) : await retrieveToken(),
            twoFa: localStorage.getItem(TWO_FA_TOKEN_KEY_NAME),
            source: 'makeAutoLogin',
          });
        } else {
          history.replace('/sign_up');
        }
      });
  }, []);

  const { component: Component } = props;

  const getContent = (): JSX.Element => {
    if (_id && (!NEW_FEATURES.workspaces || workspaceIsLoaded)) {
      return (
        <WrapperGlobal location={props.location}>
          <Component {...props} />
        </WrapperGlobal>
      );
    }

    return <LoadingPage />;
  };

  return getContent();
};

export default PR;
