import styled from '@emotion/styled/macro';

import { ModernModal } from '../../../ui/modern-modal';

export const IconMeatballsContainer = styled.div<{ isVisible?: boolean }>`
  cursor: pointer;
  visibility: ${(props): string => props.isVisible ? 'visible' : 'hidden'};
`;

export const PricingModal = styled(ModernModal)`
  width: 1330px !important;
  top: 16px;

  .ant-modal-close{
    top: 32px;
    right: 32px;
    width: 25px;
  }

  .ant-modal-close-x {
    width: auto;
    height: auto;
  }

  .ant-modal-content {
    background: var(--FFFFFF-new-pricing);
  }

  @media screen and (max-width: 1440px) {
    width: 1009px !important;
    height: 3350px;
  }

  @media screen and (max-width: 1060px) {
    width: 980px !important;
    height: 3350px;
  }
`;
